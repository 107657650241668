import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import objectSupport from 'dayjs/plugin/objectSupport'
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(isSameOrAfter);
dayjs.extend(objectSupport);

export default class SimpleDate {

  constructor(data) {
    this.date = dayjs(data);
  }

  // ------------------------
  // Used Date Formats in VHP
  // ------------------------

  shortFormat() {
    return this.date.format('YYYY-MM-DD');
  }

  longFormat() {
    return this.date.format('MMMM Do, YYYY');
  }

  timestampFormat() {
    return this.date.format('MMMM Do YYYY, h:mm a');
  }
  
  // ---------------
  // Wrapper Methods
  // ---------------

  getYear() {
    return this.date.year();
  }

  getMonth() {
    return this.date.month();
  }

  getWeekOfYear() {
    return this.date.week();
  }

  getDayOfMonth() {
    return this.date.date();
  }

  getLastDayOfMonth() {
    return this.date.daysInMonth();
  }

  isDateSameOrAfter(simple_date) {
    if(simple_date instanceof SimpleDate) {
      return this.date.isSameOrAfter(simple_date.date);
    }
  }

  getLargerDate(simple_date) {
    return this.date.isAfter(simple_date.date) ? this : simple_date; 
  }

  getSmallerDate(simple_date) {
    return this.date.isBefore(simple_date.date) ? this : simple_date; 
  }

  createJSDate() {
    return this.date.toDate();
  }

  createWeekDay(num) {
    var newDate = new SimpleDate();
    newDate.date = this.date.day(num);
    return newDate;
  }

  createNextDay() {
    var newDate = new SimpleDate();
    newDate.date = this.date.add(1, 'days');
    return newDate;
  }
}
