export function churnBar(insertions, deletions) {
  const i_blocks = Math.log10(insertions + 1)
  const d_blocks = Math.log10(deletions + 1)
  
  const i_part = (insertions <= 0) ? '' : `
    <span class="insertions n">+${insertions}</span>
    <span class="insertions-bg bar" style="width: ${i_blocks}em"></span> `
  const d_part = (deletions <= 0) ? '' : `
    <span class="deletions n">+${deletions}</span>
    <span class="deletions-bg bar" style="width: ${d_blocks}em"></span> `
  
  return `
    <span class="churn-bar">
      <span class="num-bar-container">${i_part}</span>
      <span class="num-bar-container">${d_part}</span>
    </span>`
}
