import onWelcome from "./welcome/welcome";
import onVulnerabilitiesIndex from "./vulnerabilities/index";
import onVulnerabilitiesShow from "./vulnerabilities/show";
import onVulnerabilitiesCard from "./vulnerabilities/card";
import onArticlesIndex from "./articles/index";
import onDevelopersIndex from "./developers/index";
import onDevelopersShow from "./developers/show";
import onCommitsIndex from "./commits/index";
import onFilepathsIndex from "./filepaths/index";
import onFilepathsShow from "./filepaths/show";
import onTagsIndex from "./tags/index";
import onTagsShow from "./tags/show";
import onTour from "./about/tour";
import onCurate from "./curate/curationwizard";
import onInsightsTagTrends from "./insights/tag_trends";

export default function routeToVHPView() {
  switch (
    VHP_ROUTE //defined in application.html.erb
  ) {
    case "welcome#index":
      onWelcome();
      break;
    case "vulnerabilities#index":
      onVulnerabilitiesIndex();
      break;
    case "vulnerabilities#show":
      onVulnerabilitiesShow();
      break;
    case "vulnerabilities#card":
      onVulnerabilitiesCard();
      break;
    case "articles#index":
      onArticlesIndex();
      break;
    case "developers#index":
      onDevelopersIndex();
      break;
    case "commits#index":
      onCommitsIndex();
      break;
    case "filepaths#index":
      onFilepathsIndex();
      break;
    case "tags#index":
      onTagsIndex();
      break;
    case "tags#show":
      onTagsShow();
      break;
    case "developers#show":
      onDevelopersShow();
      break;
    case "filepaths#show":
      onFilepathsShow();
      break;
    case "about#tour":
      onTour();
      break;
    case "curate#index":
      onCurate();
      break;
    case "insights#tag_trends":
      onInsightsTagTrends();
      break;
    default: // do nothing
  }
}
