/*
This file is a small utility for formatting data coming from the VHP API
It's mostly just string concatentation with some minor logic.
So that we keep things consistent.
*/

export function vulnProject(data){
    return `<img class="${data.subdomain}-inline-logo inline-logo"
                 title="${data.project_name}"/>`
}

export function vulnName(data){
  let str = data.cve;
  if(data.nickname != '' && data.nickname != null) {
    str = `<span class="nicknamed-cve"> ${data.nickname}</span>
           <span class="nicknamed-aka-cve"> aka ${data.cve}</span>`;
  }
  return str;
}

export function vulnLink(data, str) {
  return `<a data-id="${data.id}" class="dt_row" href="/${data.cve}">${str}</a>`
}

//Simplifies the CWE name if it has a nickname in parentheses
export function friendlyCWE(name) {
  // "I do not want this ('I want this') not this" --> "I want this"
  // "Just a string" --> "Just a string"
  const match = name.match(/.*\(\'(.+)\'\).*/);
  if(match){
    return match[1]; //[0] is the full string, [1] is the actual match
  } else {
    return name;
  }
}

export function ellipsizedFilepath(fp, prefixLen,maxLen) {
  /* Explanation of this magical regex
     Group 1: from the beginning of the string up to prefixLen, greedily get
              the biggest string you can ending in a /
              e.g. foo/bar/baz --> foo/ for prefixLen=3
     Group 2: at least one character must be here to be ellipsized/discarded,
              again note that we're greedy here so we ellipsize as much as
              possible before getting to group 3.
     Group 3: from the end of the string, get the smallest possible string
              starting with a / (i.e. filename)
  */
  const regex = new RegExp(`^(.{1,${prefixLen}}/)(.+)(/.+)$`, 'g')
  const replacer = function(str,prefix,_mid,suffix) {
    if(str.length > maxLen){
      return `${prefix}...${suffix}`
    } else {
      return str
    }
  }
  return [replacer, fp.replace(regex, replacer)]
}