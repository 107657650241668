import $ from 'jquery'
import { vulnTable, updateVulnTable } from './vulnTable'
// import { vulnCards } from './vulnCards'

function load_succeeded(vTable, vData) {
  $('#loading').hide();
  updateVulnTable(vTable, vData);
  // vulnCards(vData, tagMap); //SLOW this is what locks up the UI
}

function load_failed() {
  $('#loading').hide();
  $('#downloading-more').remove();
  $('#loading-failed').show();
}

export default function onVulnerabilitiesIndex(){
  // //tagMap was defined in index.html.erb
  const vulnURL = "/api/vulnerabilities?short_descriptions=true&offset=10"
  const vTable = vulnTable(initVulnData, tagMap); // init the table
  $.ajax({
    url: vulnURL,
    dataType: 'json',
    async: true,
  })
  .done((response) => load_succeeded(vTable, response))
  .fail(() => load_failed());
}