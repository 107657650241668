import $ from 'jquery'
import { vulnCards } from './vulnCards'

export default function onVulnerabilitiesCard(){
    let data = [];
    data.push(vulnerability);
    vulnCards(data, tagMap);
    $(document.body).append($(`#vuln-card-${vulnerability.id}`));
    $(`#vuln-card-${vulnerability.id}`).css({
        'background-color': 'white',
        'margin': 0
    }).addClass('no_hover');
    $(document.body).css({
        'background-color': 'transparent'
    });
}
