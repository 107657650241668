import $ from 'jquery'
import _ from 'lodash'
import 'datatables.net'
import 'datatables.net-responsive-dt'
import 'datatables.net-zf'

$.urlParam = function(name){
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results==null){
       return '';
    }
    else{
       var values = ['Project:', 'CWE', 'Language', 'Lesson', 'Severity:', 'Subsystem'];
       var search = decodeURI(results[1]) || 0;
       if (values.includes(search)){
         return search;
       }
       return '';
    }
}

function buildTopNav() {
    $('#datatable_wrapper').prepend('<div id="table-nav-top"></div>');
    const nav = $('#table-nav-top');
    nav.addClass('table-nav grid-x');

    const nav_length = $('#datatable_length');
    nav_length.addClass('table-nav-show-entries cell small-9')
    nav.append(nav_length);

    const nav_search = $('#datatable_filter label');
    nav_search.addClass('table-nav-search cell small-3')
    nav.append(nav_search);
}

function languageInfo() {
    const searchSuggestions = [
        'lesson',
        'discovered',
        'cwe',
        'injection',
        'subsystem',
        'util',
        'overflow',
        'ssl',
        'dependency',
        'severity',
        'project',
        'i18n',
        'error',
        'fix',
        'lifetime',
        'sandbox',
        'test',
        'forgot',
        'bounty',
        'stack',
        'origin',
    ]
    return {
        search: "", // nothing in the placeholder;
        searchPlaceholder: `Try "${_.sample(searchSuggestions)}"`,
        lengthMenu: "Show _MENU_",
        info: "Showing _START_ to _END_ of _TOTAL_ tags",
        infoEmpty: "Showing 0 to 0 of 0 entries",
        infoFiltered: "(filtered from _MAX_ total tags)",
    };
}

function load_succeded(jsonData) {
    $('#datatable').dataTable({
        "search": {"search": $.urlParam('search')},
        responsive: true,
        destroy: true,
        data: jsonData,
        pageLength: 50,
        columns: [
            {
                title: '<i class="vhp-icon-tags"></i> Tag',
                data: null,
                defaultContent: '',
                responsivePriority: 1,
                render: function(data, type, row) {
                    const icon = '<i class="vhp-icon-' + data.icon + '" style="color: ' + data.color + '"></i> '
                    return renderAsLink(icon + data.name, row)
                }
            },
            {
                title: 'Description',
                responsivePriority: 2,
                data: 'short_desc',
                defaultContent: '',
                render: (data, type, row) => renderAsLink(data, row)
            }
        ],
        language: languageInfo(),
        initComplete: function () {
            $('#loading').hide();
            buildTopNav();
        }
    });
}

function load_failed(jsonData) {
    $('#loading').hide();
    $('#loading-failed').show();
}

export default function onTagsIndex() {
    $.ajax({
        url: "/api/tags",
        dataType: 'json',
    }).then(load_succeded, load_failed);

    $('#datatable').on('mouseover', 'tbody tr', function() {
        $(this).css({
            'cursor': 'pointer',
            'color': 'blue'
        });
    })

    $('#datatable').on('mouseout', 'tbody tr', function() {
        $(this).css({
            'cursor': 'default',
            'color': 'inherit'
        });
    })
}

function renderAsLink(data, row) {
    return '<a class="dt_row" href="/tags/' + row.shortname + '">' + data + '</a>';
}
