import * as d3 from "d3";
import $ from "jquery";
import { vulnTable as vulnTable } from "../vulnerabilities/vulnTable";

export default function TagTrends() {
  var tagMap = null;

  //unicode mapping of icons
  const unicondeIcons = new Map([
    ["anounce", "\uf140"],
    ["auto-disc", "\uf544"],
    ["identity", "\uf2c2"],
    ["rewind", "\uf1da"],
    ["branch", "\uf126"],
    ["archway", "\uf557"],
    ["article", "\uf1ea"],
    ["atom", "\uf5d2"],
    ["balance-scale", "\uf24e"],
    ["big", "\uf49e"],
    ["c", "\uf1f9"],
    ["cpp", "\uf055"],
    ["cards", ""],
    ["cloud", "\uf0c2"],
    ["cogs", "\uf085"],
    ["contest", "\uf091"],
    ["default", "\uf46c"],
    ["dependency", "\ue335"],
    ["discussion-any", "\uf658"],
    ["discussion-sec", "\uf21b"],
    ["distrust-input", "\uf52a"],
    ["enviroment", "\uf1bb"],
    ["external", "\uf57f"],
    ["face", ""],
    ["fix", "\uf023"],
    ["file", "\uf1c9"],
    ["fix-file", "\uf0ad"],
    ["forgotten", "\uf560"],
    ["flag", "\uf024"],
    ["framework", "\uf7b5"],
    ["ghost", "\uf6e2"],
    ["github", "\uf09b"],
    ["graph-pie", "\uf200"],
    ["hand-pointer", "\uf25a"],
    ["headline", ""],
    ["hourglass", "\uf254"],
    ["info", "\uf05a"],
    ["internal", "\uf78c"],
    ["ipc", "\uf472"],
    ["java", "\uf4e4"],
    ["javascript", "\uf3b9"],
    ["key", "\uf084"],
    ["lemon", "\uf094"],
    ["microchip", "\uf2db"],
    ["microscope", "\uf610"],
    ["mistake", "\uf12e"],
    ["money", "\uf4c0"],
    ["notes", "\uf249"],
    ["order", "\uf074"],
    ["origin", "\uf534"],
    ["people", "\uf0c0"],
    ["python", "\uf3e2"],
    ["reset", "\uf0e2"],
    ["robot", "\uf544"],
    ["ruby", "\uf3a5"],
    ["running", "\uf70c"],
    ["sandbox", "\uf09c"],
    ["serial-killer", "\uf58c"],
    ["small", "\uf043"],
    ["specification", "\uf024"],
    ["stack-any", "\uf5fd"],
    ["stack-fix", "\uf5fd"],
    ["stamp", "\uf5bf"],
    ["table", "\uf0ce"],
    ["tag", "\uf02b"],
    ["tags", "\uf02c"],
    ["timeline", "\uf080"],
    ["upvote", "\uf164"],
    ["util", "\uf552"],
    ["vcc", "\uf54c"],
    ["vouch", "\uf086"],
    ["vulnerability", ""],
    ["zoom", "\uf002"],
    ["zoom-in", "\uf00e"],
    ["zoom-out", "\uf010"],
    ["i18n", "\uf57d"],
  ]);

  //short name to display name map
  const shortToDisplayNameMap = new Map([
    ["lifetime", "Lifetime"],
    ["bounty", "Bounty"],
    ["deps", "Dependency"],
    ["error of omission", "Error of Omission"],
    ["li18n", "Internationalization (i18n)"],
    ["environment_variables", "Environment Variables"],
    ["least_privilege", "Least Privilege"],
    ["origin", "Origin"],
    ["specification", "Specification"],
    ["complex_inputs", "Complex Inputs"],
    ["frameworks_are_optional", "Frameworks are Optional"],
    ["secure_by_default", "Secure by Default"],
    ["language", "Language"],
    ["security_by_obscurity", "Security by Obscurity"],
    ["vouch", "Vouch"],
    ["serial_killer", "Serial Killer"],
    ["discovered_method", "Discovered Method"],
    ["native_wrappers", "Native Wrappers"],
    ["sandbox", "Sandbox"],
    ["yagni", "Yagni"],
    ["project", "Project"],
    ["user_interaction", "User Interaction"],
    ["fix_size", "Fix Size"],
    ["ipc","Inter-Process Communication"],
    ["discussed_security","Team Discussed if Security"],
    ["discussed_any","Team Discussion on Fix"],
    ["order_of_operations","Order of Operations Mistake"],
    ["util","Fix involved Util File"],
    ["forgotten_check","Forgotten Check Mistake"],
    ["defense_in_depth","Defense in Depth"],
    // ["auto_discoverable","Autodiscoverable"], // tagger currently buggy
  ]);

  //default dropdown options
  const defaultDropdownOptions = [
    "lifetime",
    "bounty",
    "deps",
    "error of omission",
    "li18n",
    "environment_variables",
    "least_privilege",
    "origin",
    "specification",
    "complex_inputs",
    "frameworks_are_optional",
    "secure_by_default",
    "language",
    "security_by_obscurity",
    "vouch",
    "serial_killer",
    "discovered_method",
    "native_wrappers",
    "sandbox",
    "yagni",
    "project",
    "user_interaction",
    "fix_size",
    "ipc",
    "discussed_security",
    "discussed_any",
    "order_of_operations",
    "util",
    "forgotten_check",
    "defense_in_depth",
    // "auto_discoverable",
  ];

  const setData = function () {
    getJsonData();
  };

  const init = function () {
    //get the map of all tags
    $.ajax({
      type: "GET",
      url: `/api/tags?map=true`,
      dataType: "json",
      success: function (response) {
        tagMap = response;
      },
      error: function (_response) {
        console.log("Error retrieving VHP tag data. If you think this is a bug, file an issue on our GitHub (see footer)");
      },
    });

    //set the first default option in the dropdowns
    const selectLabel = '-- Tag Category --'
    $('#family1').append(new Option(selectLabel));
    $('#family2').append(new Option(selectLabel));
    $('#family3').append(new Option(selectLabel));
    $('#family4').append(new Option(selectLabel));

    setDropdowns(defaultDropdownOptions);

    $("#family1").on("change", getJsonData)
    $("#family2").on("change", getJsonData)
    $("#family3").on("change", getJsonData)
    $("#family4").on("change", getJsonData)
    $("#revialButtons").on("click", revialOrHideButtons);
    $("#swapFirst").on("click", swapFirstTwoColmns);
    $("#swapSecond").on("click", swapMiddleTwoColumns);
    $("#swapThird").on("click", swapLastTwoColumns);
    $("#revialExcludedVuls").on("click", revialOrHideExcludedVulerabilities);
    $("#closeSideBar").on("click", closeSidebar);
    $("#openSideBar").on("click", openSidebar);

     //get url params
     ///insights/tag-trends/?option=deps&option=lifetime&option=bounty&option=language
    const urlParams = new URLSearchParams(window.location.search).getAll('option');
    if (urlParams.length == 4) {
      $('#family1').val(urlParams[0])
      $('#family2').val(urlParams[1])
      $('#family3').val(urlParams[2])
      $('#family4').val(urlParams[3])
      setData()
    }
  };

  const setDropdowns = function (optionsArray) {
    const dropdowns = [
      document.getElementById("family1"),
      document.getElementById("family2"),
      document.getElementById("family3"),
      document.getElementById("family4"),
    ];

    for (let i = 0; i < dropdowns.length; i++) {
      for (let j = 0; j < optionsArray.length; j++) {
        //if there is a know display name for the option set the display as that
        // else display the option
        if (shortToDisplayNameMap.get(optionsArray[j]) !== undefined) {
          dropdowns[i].add(
            new Option(
              shortToDisplayNameMap.get(optionsArray[j]),
              optionsArray[j]
            ),
            undefined
          );
        } else {
          dropdowns[i].add(
            new Option(optionsArray[j], optionsArray[j]),
            undefined
          );
        }
      }
    }
  };

  const openSidebar = function () {
    document.getElementById("sideBar").style.width = "300px";
  };

  const closeSidebar = function () {
    document.getElementById("sideBar").style.width = "0px";
  };

  const swapFirstTwoColmns = function () {
    var currentFirstFamily = document.getElementById("family1").value;
    var currentSecondFamily = document.getElementById("family2").value;

    document.getElementById("family1").value = currentSecondFamily;
    document.getElementById("family2").value = currentFirstFamily;
    getJsonData();
  };

  const swapMiddleTwoColumns = function () {
    var currentSecondFamily = document.getElementById("family2").value;
    var currentThirdFamily = document.getElementById("family3").value;

    document.getElementById("family2").value = currentThirdFamily;
    document.getElementById("family3").value = currentSecondFamily;
    getJsonData();
  };

  const swapLastTwoColumns = function () {
    var currentThirdFamily = document.getElementById("family3").value;
    var currentFourthFamily = document.getElementById("family4").value;

    document.getElementById("family3").value = currentFourthFamily;
    document.getElementById("family4").value = currentThirdFamily;
    getJsonData();
  };

  const revialOrHideButtons = function () {
    let buttonsContainer = document.getElementById("buttonsContainer");
    let revialButtons = document.getElementById("revialButtons");

    if (buttonsContainer.style.maxHeight == "0px") {
      buttonsContainer.style.maxHeight = "30px";
      revialButtons.style.backgroundColor = "#777";
    } else {
      buttonsContainer.style.maxHeight = "0px";
      revialButtons.style.backgroundColor = "#eee";
    }
  };

  const revialOrHideExcludedVulerabilities = function () {
    let excludedVulsContainer = document.getElementById(
      "excludedVulsContainer"
    );
    let revialExcludedVuls = document.getElementById("revialExcludedVuls");

    if (excludedVulsContainer.style.maxHeight == "0px") {
      excludedVulsContainer.style.maxHeight = "30px";
      revialExcludedVuls.style.backgroundColor = "#777";
    } else {
      excludedVulsContainer.style.maxHeight = "0px";
      revialExcludedVuls.style.backgroundColor = "#eee";
    }
  };

  const getJsonData = function () {
    var firstFamily = document.getElementById("family1").value;
    var secondFamily = document.getElementById("family2").value;
    var thirdFamily = document.getElementById("family3").value;
    var fourthFamily = document.getElementById("family4").value;

    //make sure all families are selected
    if (
      firstFamily != "-- Select Tag Category --" &&
      secondFamily != "-- Select Tag Category --" &&
      thirdFamily != "-- Select Tag Category --" &&
      fourthFamily != "-- Select Tag Category --"
    ) {
      //if the same family is selected more than once warn the user
      if (
        firstFamily == secondFamily ||
        firstFamily == thirdFamily ||
        firstFamily == fourthFamily ||
        secondFamily == thirdFamily ||
        secondFamily == fourthFamily ||
        thirdFamily == fourthFamily
      ) {
        alert("Please select distinct categories in each dropdown");
      } else {
        $.ajax({
          type: "GET",
          url: "/api/insights/tag-trends",
          data: {
            family1: firstFamily,
            family2: secondFamily,
            family3: thirdFamily,
            family4: fourthFamily,
          },
          dataType: "json",
          success: function (response) {
            checkFamilyTagLinks();
            removeDiagram();

            //display the swap and excluded vulerabilities buttons
            document.getElementById("revialButtons").style.display = "block";
            document.getElementById("revialExcludedVuls").style.display =
              "block";
            createDiagram(response);
          },
          error: function (response) {
            createDiagram(response);
          },
        });
      }
    }
  };

  //checking the links between families to send messages about no links between specific families.
  const checkFamilyTagLinks = function () {
    var firstFamily = document.getElementById("family1").value;
    var secondFamily = document.getElementById("family2").value;
    var thirdFamily = document.getElementById("family3").value;
    var fourthFamily = document.getElementById("family4").value;

    $.ajax({
      type: "GET",
      url: "/api/insights/family-tag-links",
      data: {
        family1: firstFamily,
        family2: secondFamily,
        family3: thirdFamily,
        family4: fourthFamily,
      },
      dataType: "json",
      success: function (response) {
        var familyWarningBox = document.getElementById("familyWarning");
        familyWarningBox.style.display = "block";
        var links = response[0];

        //set the excluded vulnerabilities display text
        document.getElementById("excludedVulsContainer").textContent =
          "There are " +
          links.excludedvulnerabilities +
          " vulnerabilities that are not part of any of the categories selected.";

        //display a warning based on which families have no links between them
        if (
          links.firstlink == 0 &&
          links.secondlink == 0 &&
          links.thirdlink == 0
        ) {
          familyWarningBox.textContent =
            "No directed connections between the any of the tag categories, a blank diagram will be displayed.";
        } else if (links.firstlink == 0 && links.secondlink == 0) {
          familyWarningBox.textContent =
            "No connections between the first, second, and third tag categories.";
        } else if (links.secondlink == 0 && links.thirdlink == 0) {
          familyWarningBox.textContent =
            "No connections between the second, third, and fourth tag categories.";
        } else if (links.firstlink == 0 && links.thirdlink == 0) {
          familyWarningBox.textContent =
            "No connections between the first and second tag categories. Also no connections between the third and fourth tag categories.";
        } else if (links.firstlink == 0) {
          familyWarningBox.textContent =
            "No connections between the first and second tag categories.";
        } else if (links.secondlink == 0) {
          familyWarningBox.textContent =
            "No connections between the second and third tag categories.";
        } else if (links.thirdlink == "0") {
          familyWarningBox.textContent =
            "No connections between the third and fourth tag categories.";
        } else {
          //hide warrning box
          familyWarningBox.style.display = "none";
        }

        //var excludedVulerabilities = links
      },
      error: function (response) {
        familyWarningBox.style.display = "none";
      },
    });
  };

  const removeDiagram = function () {
    //removing the current diagram
    var diagram = document.getElementById("tag-trends");
    diagram.parentNode.removeChild(diagram);

    //find the tag tends description for the placement of the new diagram below
    const tagDescription = document.getElementById("diagramDescription");

    //create the new diagram div
    const div = document.createElement("div");
    div.setAttribute("id", "tag-trends");
    div.setAttribute("style", "padding-left: 130px");

    //place new diagram
    tagDescription.parentNode.insertBefore(div, tagDescription.nextSibling);

    //if the diagram changes the vuln table must as well
    removeVulnTable();
  };

  const removeVulnTable = function () {
    //hide the vulntable label
    document.getElementById("vulnTableDescription").style.display = "none";

    //removing the current vulnerability table
    var vulnTableWrapper = document.getElementById("vulndatatable_wrapper");
    if (vulnTableWrapper) {
      vulnTableWrapper.parentNode.removeChild(vulnTableWrapper);
    }

    //find the vulnerability table discription for the placement of the new table below
    const vulnDescription = document.getElementById("vulnTableDescription");

    //create the new vulnerability table
    const table = document.createElement("table");
    table.setAttribute("id", "vulndatatable");
    table.setAttribute("class", "display");
    table.setAttribute("width", "100%");

    //place new vulnerability table
    vulnDescription.parentNode.insertBefore(table, vulnDescription.nextSibling);
  };

  const createDiagram = function (jsonData) {
    //make the diagram label visable
    document.getElementById("diagramDescription").style.display = "block";

    var margin = { top: 10, right: 10, bottom: 10, left: 10 },
      width = 1000 - margin.left - margin.right,
      height = 850 - margin.top - margin.bottom;

    var d3Sankey = require("d3-sankey");
    var d3 = require("d3");

    //color for selected nodes and links
    const colorWhenSelected = "#000";
    //minumum width for links
    const minimumLinkWidth = 3;

    //Selected Nodes
    var selectedNodes = [];

    var svg = d3
      .select("#tag-trends")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    //Color scale
    var color = d3.scaleOrdinal(d3.schemeCategory10);

    //Scaling of the diagram itself
    var scale = d3
      .scaleLinear()
      .domain([0, 1]) //input
      .range([0, 1]); //output

    var sankey_diagram = d3Sankey
      .sankey()
      .nodeId(function (d) {
        return d.name;
      })
      .nodeWidth(scale(30))
      .nodePadding(scale(10))
      .size([scale(width), scale(height)]);

    var graph = sankey_diagram(jsonData);

    sankey_diagram.nodes(graph.nodes).links(graph.links);

    // add in the links
    var link = svg
      .append("g")
      .selectAll(".link")
      .data(graph.links)
      .enter()
      .append("path")
      .attr("class", "link")
      .attr("d", d3Sankey.sankeyLinkHorizontal())
      .attr("id", function (d, i) {
        d.id = i;
        return "link-" + i;
      })
      .style("stroke-width", ({ width }) => Math.max(minimumLinkWidth, width))
      .style("fill", "none")
      .style("stroke", (d, i) => {
        // making unique gradient ids
        const gradientID = `gradient${i}`;

        //database coloring
        var startColor = d.source.color;
        const stopColor = d.target.color;

        const defs = svg.append("defs");
        const linearGradient = defs
          .append("linearGradient")
          .attr("id", gradientID);
        linearGradient
          .attr("gradientUnits", "userSpaceOnUse")
          .attr("x1", d.source.x1)
          .attr("x2", d.target.x0)
          .call((gradient) =>
            gradient
              .append("stop")
              .attr("offset", "0%")
              .attr("stop-color", stopColor)
          )
          .call((gradient) =>
            gradient
              .append("stop")
              .attr("offset", "100%")
              .attr("stop-color", startColor)
          );
        return `url(#${gradientID})`;
      })
      .style("stroke-opacity", 0.4)
      .on("mouseover", function (d) {
        d3.select(this).style("stroke-opacity", 0.2);
      })
      .on("mouseout", function (d) {
        d3.select(this).style("stroke-opacity", 0.4);
      })
      .sort(function (a, b) {
        return b.y1 - b.y0 - (a.y1 - a.y0);
      })
      .append("title")
      .text(function (d) {
        return (
          d.source.displayName +
          " → " +
          d.target.displayName +
          "\n" +
          "Total Vulnerabilities: " +
          d.value
        );
      });

    //add in the nodes
    var node = svg
      .append("g")
      .selectAll(".node")

      .data(graph.nodes)
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", function (d) {
        return "translate(" + d.x0 + "," + d.y0 + ")";
      });

    // add the rectangles for the nodes
    node
      .append("rect")
      .attr("height", function (d) {
        return scale(d.y1 - d.y0);
      })
      .attr("width", sankey_diagram.nodeWidth())
      .style("fill", function (d) {
        //database coloring
        return d.color;
      })
      .style("stroke", function (d) {
        return d3.rgb(d.color).darker(2);
      })
      .style("cursor", "move")
      .style("fill-opacity", 0.9)
      .style("shape-rendering", "crispEdges")
      .on("mouseover", function (d) {
        d3.select(this).style("fill-opacity", 0.4);
      })
      .on("mouseout", function (d) {
        d3.select(this).style("fill-opacity", 0.8);
      })
      .on("click", function (event, d) {
        //if ctrl clicked go to tag page
        if (event.ctrlKey && d.name !== undefined) {
          location.href = `/tags/${d.name}`;
        }
        // else add node to path of vulnerabilities
        else {
          //add/remove node
          let added = haddleSelectedNode(d3.select(this).property("__data__"));

          //change node color
          if (added) {
            d3.select(this).style("fill", colorWhenSelected);
          } else {
            d3.select(this).style(
              "fill",
              d3.select(this).property("__data__").color
            );
          }

          //change link color
          haddleSelectedLinksColor(d3.select(this).property("__data__"));

          //update vuln table
          discriptionGenerator();
        }
      })
      //hover text
      .append("title")
      .text(function (d) {
        return d.displayName + "\n" + "Total: " + d.value;
      })
      .style("select", "none")
      .style("text", "none")
      .style("text-shadow", "0 10000px 0 #fff");

    // add in the title for the nodes
    node
      .append("text")
      .attr("x", -6)
      .attr("y", function (d) {
        return Math.abs((d.y1 - d.y0) / 2);
      })
      .attr("dy", ".35em")
      .attr("text-anchor", "end")
      .attr("transform", null)
      .text(function (d) {
        return d.displayName;
      })
      .filter(function (d) {
        return d.x0 < width / 2;
      })
      .attr("x", 6 + sankey_diagram.nodeWidth())
      .attr("text-anchor", "start");

    //add icons to the center of the nodes
    node
      .append("text")
      .attr("x", sankey_diagram.nodeWidth() / 2)
      .attr("y", function (d) {
        return Math.abs((d.y1 - d.y0) / 2) + 2;
      })
      .attr("text-anchor", "middle")
      .style("border", "2px white")
      .attr("font-family", "FontAwesome")
      .attr("font-size", "10px")
      .text(function (d) {
        //only display if 15px or greater
        if (scale(d.y1 - d.y0) >= 15) {
          return unicondeIcons.get(d.icon);
        }
        return null;
      });

    // the function for moving the nodes but this doesn't work
    function dragmove(d) {
      d3.select(this).attr(
        "transform",
        "translate(" +
          d.x0 +
          "," +
          (d.y0 = Math.max(0, Math.min(height - (d.y1 - d.y0), d3.event.y))) +
          ")"
      );
      sankey.relayout();
      link.attr("d", d3Sankey.sankeyLinkHorizontal());
    }

    //haddles adding or removing a node from the selected node array and returns true if added false if removed
    function haddleSelectedNode(node) {
      var newNode = true;

      for (let i = 0; i < selectedNodes.length && newNode; i++) {
        if (selectedNodes[i].index == node.index) {
          newNode = false;
          selectedNodes.splice(i, 1);
        }
      }

      if (newNode) {
        selectedNodes.push(node);
      }

      return newNode;
    }

    function haddleSelectedLinksColor(node) {
      var found = false;

      //if the node is selected check for link paths, else deselect all links
      if (selectedNodes.some((e) => e.name == node.name)) {
        //check source links
        for (let i = 0; i < node.sourceLinks.length; i++) {
          for (let j = 0; j < selectedNodes.length && !found; j++) {
            found = node.sourceLinks[i].target.index == selectedNodes[j].index;
          }
          setLinkColor(node.sourceLinks[i].id, found, node.sourceLinks[i]);
          found = false;
        }

        //check target links
        for (let i = 0; i < node.targetLinks.length; i++) {
          for (let j = 0; j < selectedNodes.length && !found; j++) {
            found = node.targetLinks[i].source.index == selectedNodes[j].index;
          }
          setLinkColor(node.targetLinks[i].id, found, node.targetLinks[i]);
          found = false;
        }
      } else {
        //clear source links
        for (let i = 0; i < node.sourceLinks.length; i++) {
          setLinkColor(node.sourceLinks[i].id, found, node.sourceLinks[i]);
        }

        //clear target links
        for (let i = 0; i < node.targetLinks.length; i++) {
          setLinkColor(node.targetLinks[i].id, found, node.targetLinks[i]);
        }
      }
    }

    function setLinkColor(id, selected, link) {
      if (selected) {
        d3.select("#link-" + id).style("stroke", colorWhenSelected);
        d3.select("#link-" + id).style("stroke-opacity", 1);
        d3.select("#link-" + id)
          .on("mouseover", function (d) {
            d3.select(this).style("stroke-opacity", 0.5);
          })
          .on("mouseout", function (d) {
            d3.select(this).style("stroke-opacity", 1);
          });
      } else {
        d3.select("#link-" + id).style("stroke-opacity", 0.4);
        d3.select("#link-" + id).style("stroke", `url(#gradient${link.index})`);
        d3.select("#link-" + id)
          .on("mouseover", function (d) {
            d3.select(this).style("stroke-opacity", 0.2);
          })
          .on("mouseout", function (d) {
            d3.select(this).style("stroke-opacity", 0.4);
          });
      }
    }

    function discriptionGenerator() {
      //short name array
      let namesArray = "";
      //display names array
      let discription = "";

      for (let i = 0; i < selectedNodes.length; i++) {
        if (namesArray.length == 0) {
          namesArray = selectedNodes[i].name;
          discription = selectedNodes[i].displayName;
        } else {
          namesArray = namesArray + "," + selectedNodes[i].name;
          discription = discription + "," + selectedNodes[i].displayName;
        }
      }

      removeVulnTable();

      if (discription.length > 0) {
        getTagOverlaps(namesArray, discription);
      }
    }

    function getTagOverlaps(namesArray, discription) {
      $.ajax({
        type: "GET",
        url: "/api/tags/overlaps",
        data: {
          tag_list: namesArray,
          limit: 100000,
          offset: 0,
        },
        dataType: "json",
        success: function (response) {
          //parse the returned json data so it is not just a string
          response.forEach((item) => {
            item.tag_json = JSON.parse(item.tag_json);
          });

          //change label based on selected nodes and reviel the label
          document.getElementById("vulnTableDescription").innerHTML =
            vulnerabilityLabelGenerator(discription);

          document.getElementById("vulnTableDescription").style.display =
            "block";

          vulnTable(response, tagMap, "#vulndatatable");
        },
        error: function (response) {
          console.log(response);
        },
      });
    }

    function vulnerabilityLabelGenerator(namesArray) {
      let label = "Vulnerabilities tagged with";
      let splitArray = namesArray.split(",");

      if (splitArray.length == 1) {
        label = label + " " + splitArray[0];
      } else if (splitArray.length == 2) {
        label = label + splitArray[0] + " and " + splitArray[1];
      } else {
        label = label;
        for (let i = 0; i < splitArray.length; i++) {
          if (i + 1 < splitArray.length) {
            label = label + " " + splitArray[i] + ",";
          } else {
            label = label + " and " + splitArray[0];
          }
        }
      }

      return label;
    }
  };

  init();
}
export function onInsightsTagTrends() {
  TagTrends();
}
