const toggleBtn = document.getElementById("themeToggle");

function prefersLightTheme(){
	return window.matchMedia("(prefers-color-scheme: light)").matches;
}

function setThemeDark(isDark){
	document.body.classList.add(isDark ? 'dark-mode' : 'light-mode');
	document.body.classList.remove(isDark ?  'light-mode' : 'dark-mode');
	toggleBtn.checked = isDark;
	localStorage.setItem("use-dark-mode", isDark)
}

function currentThemeIsDark(){
	return document.body.classList.contains("dark-mode")
}

function decideUseDark(){
	switch(localStorage.getItem("use-dark-mode")){
		case "true":
			return true
		case "false":
			return false
		default:
			return prefersLightTheme()
			// if you have zero preferences and history... DARK MODE!
			// ...because Andy likes VHP dark mode better.
	}
}

/*
  How we determine light/dark mode:

  Precedence:
	1. localStorage
	2. perfers-color-scheme
	3. Dark mode

  Then: store in localStorage after determine from precedence
 */
export default function determineLightDarkMode() {
	const useDarkMode = decideUseDark();
	toggleBtn.addEventListener("click",() => setThemeDark(!currentThemeIsDark()));
	setThemeDark(useDarkMode)
}