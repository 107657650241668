import $ from 'jquery'
import _ from 'lodash'
import 'datatables.net'
import 'datatables.net-responsive-dt'
import 'datatables.net-zf'
import { churnBar as churnBar } from '../global/churnBar'
import { hideSinglePagePagination as hideSinglePagePagination } from '../global/datatablesTweaks'
import SimpleDate from '../global/simpleDate'

export function keyCommitsTable(jsonData, tableName = '#edit_table') {
    $(tableName).DataTable({
        responsive: true,
        autoWidth: false,
        destroy: true,
        data: jsonData,
        // DOM option here is SUPER confusing.
        // See https://datatables.net/reference/option/dom
        // and https://github.com/DataTables/Dist-DataTables-Foundation/blob/master/js/dataTables.foundation.js#L56-L60
        dom: "<'row grid-x'<'small-2 columns cell'l><'small-3 small-offset-7 columns cell'f>r>"+
             "t"+
             "<'row grid-x'<'small-3 columns cell'i><'small-9 columns cell'p>>",
        lengthMenu: [
            [10, 25, 50, 100],
            ["Show 10", "Show 25", "Show 50", "Show 100"]
        ],
        columns: [
            {
                title: 'Commit',
                defaultContent: '',
                responsivePriority: 1,
                data: null,
                render: (c) => renderAsDiv(`
                <div>
                    <span class="sha">${c.commit_hash}</span>
                    <span class="churn-line">
                        ${churnBar(c.notes.insertions, c.notes.deletions)}
                    </span>
                </div>
                <pre>${c.message.split("\n")[0]}</pre>`, c.id)
            },
            {
                title: 'Date',
                defaultContent: '',
                responsivePriority: 2,
                width: '8em',
                data: null,
                render: (c) => renderAsDiv(
                    `${new SimpleDate(c.date_created).shortFormat()}`,
                c.id)
            },
            {
                title: 'VCC?',
                defaultContent: '',
                responsivePriority: 3,
                width: '5em',
                data: null,
                render: (c) => c.is_vcc ? '<i class="vhp-icon-vcc"></i>' : ''
            },
            {
                title: 'Fix?',
                defaultContent: '',
                responsivePriority: 4,
                width: '5em',
                data: null,
                render: (c) => c.is_fix ? '<i class="vhp-icon-fix"></i>' : ''
            },
            ],
        language: commitsLanguageInfo(),
        preDrawCallback: (settings) => hideSinglePagePagination(settings),
        initComplete: () => $('#loading').remove()
    });

    $(tableName).on('mouseover', 'tbody tr', function () {
        $(this).css({
            'cursor': 'pointer',
            'color': 'blue'
        });
    })

    $(tableName).on('mouseout', 'tbody tr', function () {
        $(this).css({
            'cursor': 'default',
            'color': 'inherit'
        });
    });
};

function commitsLanguageInfo() {
    return {
        search: "", // nothing in the placeholder;
        searchPlaceholder: 'Search',
        lengthMenu: '_MENU_',
        info: "Showing _START_ to _END_ of _TOTAL_ key commits",
        infoEmpty: "Showing 0 to 0 of 0 entries",
        infoFiltered: "(filtered from _MAX_ total commits)",
    };
}

function renderAsDiv(str,commit_id) {
    return `<a class="dt_row" href="/commits/${commit_id}">
              <div class="edit-cell-overflow">${str}</div>
            </a>`;
}