import $ from "jquery";
import { select, pointer, scaleLinear, scaleSqrt, arc } from "d3";
import * as d3 from "d3";
import _ from "lodash";
import { ellipsizedFilepath } from "../global/dataToPrettyStrings";

var project_id = 0; //project_id 0 is "all" projects
var cutoff = 2; // number of fixes we need to actually show this. TODO make this configurable
var svg = null;
var projectColors = [];
var allProjects = [];
var allOffenders = [];
var tooltipRef = null;
var radius = null;
var gVar = null;

export default function OffenderMap() {
  this.init = function () {
    this.initSVG();
    this.registerHandlers();
    return this;
  };

  this.initSVG = function () {
    const width = 700; //internal coordinates
    const height = 700;
    radius = Math.min(width, height) / 2;
    svg = select("#offender-map-svg");
    // .append("svg:svg")
    // // svg width and height NOT set for responsiveness here!!
    // // viewBox is the internal coordinate system and then this
    // // scales to fit its container
    // .attr("viewBox", `0 0 ${width} ${height}`)
    // .attr("preserveAspectRatio", "xMidYMid meet")
    gVar = svg
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);
    addHelpText("Click to zoom", 20);
    addHelpText("Ctrl+Click leaf node to visit", 35);
    addHelpText("Click center to go up", 50);
    addToolTip();
  };

  this.setData = function (offenders, projects) {
    allOffenders = offenders;
    allProjects = projects;
    projectJSONToMap();
    prependProjectName();
    addProjectLegends();
    this.rebuild();
  };

  // e.g. Chromium's foo/bar.c --> chromium/foo/bar.c
  const prependProjectName = function () {
    allOffenders = allOffenders.map(function (f) {
      f.filepath = `${f.subdomain}/${f.filepath}`;
      return f;
    });
  };

  // Draw the help text at the given y coordinate
  const addHelpText = function (str, y) {
    svg
      .append("text")
      .attr("x", 480)
      .attr("y", y)
      .attr("font-size", 15)
      .attr("style", "fill: #aaa")
      .html(str);
  };

  const addToolTip = function () {
    tooltipRef = svg
      .append("text")
      .attr("x", 0)
      .attr("y", 680)
      .attr("font-size", 11)
      .attr("font-family", "Consolas, 'Liberation Mono', Courier, monospace")
      .attr("style", "fill: #000");
  };

  this.registerHandlers = function () {
    $("select.project-choice").on("change", (e) => {
      project_id = $("select.project-choice option:selected").attr("value");
      this.rebuild();
    });
  };

  // the side project labels
  const addProjectLegends = function () {
    let y = 130;
    let localSvg = svg;
    allProjects.forEach(function (p) {
      localSvg
        .append("text")
        .attr("x", 0)
        .attr("y", y)
        .attr("font-size", 15)
        .attr("font-weight", "bold")
        .style("fill", p.bg_color)
        .html(p.name);
      y -= 17;
    });
  };

  this.rebuild = function () {
    const cluster = rebuildDataHierarchy();
    this.rebuildVisualization(cluster);
  };

  // Convert from the JSON response from the API to a formatted needed for //
  // D3's hierarchical clustering.
  // 1. Filter out according to project & num_fixes cutoff
  // 2. Prepend the project name to the filepath
  // 2. Parentify - add parent directories to the data and keep it sorted
  // 3. Clusterify - convert to D3's clustered hierarchy data structure,
  // 4. Partition - append data structure with layout X's & Y's
  const rebuildDataHierarchy = function () {
    const filtered = allOffenders.filter(
      //accept only filepaths
      (f) =>
        (f.project_id == project_id || project_id == 0) && f.num_fixes >= cutoff
    );
    const cluster = clusterify(parentify(filtered));
    var partition = d3.partition();
    partition(cluster);
    return cluster;
  };

  const setXValue = function () {
    return scaleLinear().range([0, 2 * Math.PI]);
  };

  const setYValue = function () {
    return scaleSqrt().range([0, radius]);
  };

  /*
    Given a D3 partition()'d cluster hierarchy, draw stuff partition() computed
    X's and Y's for us, but it's in its own coordinate space AND uses a
    traditional rectangle-based hierarchy. So we need to map those numbers to a
    circular pattern in our own coordinate system. Then do styling with colors
    and other visual fanciness.
    */
  this.rebuildVisualization = function (cluster) {
    this.x = setXValue();
    this.y = setYValue();

    //from rectangles to divided circles
    this.arc = arc()
      .startAngle((d) => Math.max(0, Math.min(2 * Math.PI, this.x(d.x0))))
      .endAngle((d) => Math.max(0, Math.min(2 * Math.PI, this.x(d.x1))))
      .innerRadius((d) => Math.max(0, this.y(d.y0)))
      .outerRadius((d) => Math.max(0, this.y(d.y1)));

    console.log(this.x.domain());

    const colorScale = d3.interpolateRgb("#774b70", "#ce6dbd");
    const cluster_depth = cluster.height;
    const color = d3.scaleSequential(colorScale).domain([0, cluster_depth]);

    gVar.selectAll("path").remove(); // remove previous if there
    gVar
      .selectAll("path")
      .data(cluster.descendants())
      .enter()
      .append("path")
      .attr("d", this.arc)
      .style("stroke", "#fff")
      .style("stroke-width", "0.25px")
      .style("fill", (d) => computeColor(d.data.project_id, d.depth))
      .on("click", (event, d) => clicked(event, d, this))
      .on("mouseover", (event, d) => mouseovered(event, d, this))
      .attr("transform", "scale(0.05)")
      .transition()
      .attr("transform", "scale(1.1)")
      .duration(200) //ms
      .transition()
      .attr("transform", "scale(1.0)")
      .duration(300); //ms
  };

  // D3's stratify method needs the parent directories, and sorted
  // e.g. filepath: foo/bar/baz.c
  //         ---converts too---
  //     /
  //     /foo
  //     /foo/bar
  //     /foo/bar/baz.c
  // We also need to make sure this is all unique,
  // (e.g. there's only one /foo), so we use hashes
  const parentify = function (offenderList) {
    const parents = { "/": 0 }; // init with root directory, purple
    offenderList.forEach(function (entry) {
      let filepath = entry.filepath;
      while (filepath.indexOf("/") > 0) {
        filepath = filepath.substring(0, filepath.lastIndexOf("/"));
        parents[filepath] = entry.project_id;
      }
    });
    for (let key in parents) {
      offenderList.push({
        filepath: key,
        id: null, // there is no filepath ID for this, it's a directory!
        num_fixes: null, //init this to null - it'll be updated later
        project_id: parents[key],
      });
    }
    offenderList.sort(function (a, b) {
      // sort these alphabetically
      return a.filepath > b.filepath ? 1 : b.filepath > a.filepath ? -1 : 0;
    });
    return offenderList;
  };

  // Given filepath data with parents, generate a cluster hierarchy
  // Also: add up the values of each parent
  const clusterify = function (data) {
    const stratify = d3
      .stratify()
      .parentId(function (d) {
        if (d.filepath == "/") return null;
        if (d.filepath.indexOf("/") > 0) {
          // non-root file
          return d.filepath.substring(0, d.filepath.lastIndexOf("/"));
        } else {
          //root file
          return "/";
        }
      })
      .id(function (d) {
        return d.filepath;
      });
    let root = stratify(data);
    root.sum(function (d) {
      return d.num_fixes;
    });
    return root;
  };

  // Ctrl+Click means we go to the page, the vulnerabilites table on that page
  // Click means we zoom in
  const clicked = function (event, d, map) {
    let macPlatforms = ["Macintosh", "MacIntel", "NacPPC", "Mac68K"];

    const [pointerX, pointerY] = pointer(event);
    if (event.ctrlKey && d.data.slug !== undefined) {
      location.href = `/filepaths/${d.data.slug}#vulnerabilities`;
    }
    //check if Mac OS
    else if (
      macPlatforms.indexOf(navigator.userAgentData.platform) !== -1 &&
      handleMacCommandClick(event, d)
    ) {
      location.href = `/filepaths/${d.data.slug}#vulnerabilities`;
    } else {
      zoom(event, d, map);
    }
  };

  var pressedKeys = [];

  //add pressed keys to array
  $(document.body).on("keydown", function (event) {
    pressedKeys.push(event.originalEvent.keyCode);
  });

  //remove pressed keys from array
  $(document.body).on("keyup", function (event) {
    var pos = pressedKeys.indexOf(event.originalEvent.keyCode);
    if (pos > 1) {
      pressedKeys.splice(pos, 1);
    }
  });

  //due to how js works with mac computers detecting command+click is browser dependent
  const handleMacCommandClick = function (event, d) {
    // key code matching based on browsers and webkit versions
    const mozillaCommandKeyCode = 224;
    const operaCommandKeyCode = 17;
    const webKitGreaterTan525Left = 91;
    const webKitGreaterTan525Right = 93;

    //check for AppleWebKit and that its version is greater than 525
    if (
      navigator.userAgent.indexOf("AppleWebKit") !== -1 &&
      navigator.userAgent
        .substring(navigator.userAgent.indexOf("AppleWebKit") + 12)
        .split(" ")[0] > 525
    ) {
      //check pressed keys
      if (
        pressedKeys.includes[webKitGreaterTan525Left] ||
        pressedKeys.includes[webKitGreaterTan525Right]
      ) {
        return true;
      }
    }

    //check if Chrome, Safari, or Oprea
    if (
      navigator.userAgent.indexOf("Chrome") !== -1 ||
      navigator.userAgent.indexOf("Safari") ||
      navigator.userAgent.indexOf("OP") !== -1
    ) {
      //check pressed keys
      if (pressedKeys.includes[operaCommandKeyCode]) {
        return "true";
      }
    }
    //check if Firefox
    if (navigator.userAgent.indexOf("Firefox") > -1) {
      //check pressed keys
      if (pressedKeys.includes[mozillaCommandKeyCode]) {
        return "true";
      }
    }

    return false;
  };

  // Zooming is accomplished by changing how the xy's are interpolated
  const zoom = function (event, d, map) {
    svg
      .transition()
      .duration(750) // ms
      .attrTween("scale", function () {
        const [pointerX, pointerY] = pointer(event);
        var xd = d3.interpolate(map.x.domain(), [d.x0, d.x1]),
          yd = d3.interpolate(map.y.domain(), [d.y0, 1]),
          yr = d3.interpolate(map.y.range(), [d.y0 ? 20 : 0, radius]);
        return function (t) {
          map.x.domain(xd(t));
          map.y.domain(yd(t)).range(yr(t));
          return map.arc(d);
        };
      })
      .selectAll("path")
      .attrTween("d", function (d) {
        return function () {
          return map.arc(d);
        };
      });
  };

  // Init a lookup table for project colors
  const projectJSONToMap = function () {
    const reducer = function (pcMap, jsonRow) {
      pcMap[jsonRow.id] = jsonRow.bg_color;
      return pcMap;
    };
    projectColors = _.reduce(allProjects, reducer, { 0: "#F53663" });
  };

  // Actually use the lookup table.

  const computeColor = function (project_id, _depth) {
    return projectColors[project_id];
  };

  const mouseovered = function (event, d, self) {
    const [pointerX, pointerY] = pointer(event);
    if (d.data.id) {
      //is this a filepath?
      tooltipRef
        .style("visibility", "visible")
        .style("opacity", "100")
        .style("top", pointerY + "px")
        .style("left", pointerX + "px")
        .html(
          `<a href=/filepaths/${d.data.slug}>${
            ellipsizedFilepath(d.data.filepath, 20, 75)[1]
          }</a>`
        );
    } else {
      // or a directory?
      tooltipRef
        .style("visibility", "visible")
        .style("opacity", "100")
        .style("top", pointerY + "px")
        .style("left", pointerX + "px")
        .html(ellipsizedFilepath(d.data.filepath, 20, 75)[1]);
    }
  };
}
