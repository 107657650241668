import $ from 'jquery'
import { select, selectAll, scaleTime, scaleLinear, axisBottom } from 'd3';
import SimpleDate from '../global/simpleDate'

/**
 * Object representation of the horizontal timeline with
 * some helper methods to display the vertical timeline
 *
 * @param events - collection of events from backend sorted chronologically
 * @param timelineHeight - initial height of the timeline (in em)
 * @param chicHeight (int) - height of a chiclet
 * @param chicWidth (int) - width of a chiclet
 * @param defaultZoomValue (string) - zoom level that the timeline will default to
 * @param eDict (Dict<Date, Array<Event1>>) - dict representing the columns
 *  of the horizontal timeline
 * @param minDate (Date) - absolute minimum date from all events
 * @param maxDate (Date) - absolute maximum date from all events
 * @constructor
 */
export default class Timeline{

  constructor (settings) {
    this.timelineHeight = settings.timelineHeight;
    this.additionalHeight = 0; // Any extra height added to the timeline (in em)
    this.chicHeight = settings.chicHeight;
    this.chicWidth = settings.chicWidth;
    this.iconCornerRadius = 4;
    this.absMinDate = new Date(1991, 8, 6); // A day that will live in infamy.
    this.absMaxDate = new Date();
    this.minEventDate = this.absMinDate; // Temp placeholders until the exact
    this.maxEventDate = this.absMaxDate; // dates can be determined
    this.minViewDate = this.absMinDate;
    this.maxViewDate = this.absMaxDate;
    this.xAxisHeight = settings.xAxisHeight;
    this.overflowHeight = 20;
    this.fade_ms = settings.fade_ms;
    this.validTypes = [];
    this.inactiveToggles = [];
    this.activeToggles = [];
    this.svg;
    this.x;
    this.y;
    this.defaultZoomValue = settings.defaultZoomValue;
  }

  /**
   * Initialize the SVG, but don't draw events yet.
   */
  init() {
      this.removeSVG();
      this.initSVG();
      this.numCols = Math.floor(this.svgWidth / this.chicWidth) - 1;
      this.fadeInSVG();
  }

  removeSVG() {
      select("#hTimeline").remove();
  }

  initXAxisScale() {
      var halfChicWidth = this.chicWidth / 2;
      this.x = scaleTime()
                .domain([this.minViewDate, this.maxViewDate])
                .range([halfChicWidth, this.svgWidth - halfChicWidth]);
  }

  initYAxisScale() {
      this.chicLimit = Math.floor(this.plotHeight / (this.chicHeight + 1));

      this.y = scaleLinear()
                  .domain([0, this.chicLimit])
                  .range([this.plotHeight,
                          this.overflowHeight - this.chicHeight]);
  }

  initSVG() {
    this.svg = select('#horizontal-timeline').append("svg")
        .attr("id", "hTimeline")
        .attr('opacity', 0)
        // svg width is dynamic, stretched to container - so not set.
        // svg height is hardcoded to 16ems (e.g. 256px)
        // viewBox is not used either (unlike our other SVGs)
        // i.e. so our aspect ratio is not fixed at all
        .attr('width', '100%')
        .attr('height', this.timelineHeight + this.additionalHeight + 'em');
    this.svgWidth = parseInt(this.svg.style("width")); // compute px
    this.svgHeight = parseInt(this.svg.style("height")); // compute px
    this.initXAxisScale();
    // y-axis scale goes from the x-axis (with tiny a 2px gap) up to the top
    // of the SVG, with space for overflow icons
    this.plotHeight = this.svgHeight - this.xAxisHeight - this.overflowHeight - 2;

    this.initYAxisScale();
  }

  /**
   * draw the x axis for the horizontal timeline
   */
  drawXAxis() {
    // My logic is this: "September" is the longest text we need, 9 letters
    // Assume font size 1em, so that will be generously 9-ems wide
    // Give an additional 1-em space between labels. So 10 ems per label.
    // Assume 1em = 16px. Bad assumption, I know - we can improve that.
    // So the magic number is 160px. If that's bad, feel free to update.
    // Then 2 more for the endcaps
    var numTicks = parseInt(this.svgWidth / 160) + 1;
    var xAxis = axisBottom()
              .scale(this.x)
              .ticks(numTicks);
    var g = select('#htimeline-xaxis');
    if (g.empty()) {
        g = this.svg.append("g").attr("id", "htimeline-xaxis");
    } else {
        g.html(''); // clear it out
    }
    g.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + (this.svgHeight - this.xAxisHeight / 2) + ")")
      .call(xAxis);

    g.append("g")
      .attr("class", "x axis project-events")
      .append("rect")
      .attr("width", this.svgWidth)
      .attr("height", this.xAxisHeight / 2)
      .attr("transform", "translate(0," + (this.svgHeight - this.xAxisHeight) + ")");
  };

  // Do a CSS fade in
  fadeInSVG() {
      select('#hTimeline')
        .transition()
        .duration(750)
        .attr('opacity', 1);
  };

  // Do a CSS fade in
  fadeOutSVG() {
      select('#hTimeline')
        .transition()
        .duration(750)
        .attr('opacity', 0);
  };

  // Determines which toggles are active after a context change (like zoom in)
  updateToggledFilters() {
    this.validTypes = []
    this.activeToggles = []

    var self = this;
    this.events.forEach(function(e) {
      if (self.shouldDrawToggle(e)) {
        if (!self.validTypes.includes(e.event_type)) {
          self.validTypes.push(e.event_type);
        }
      }

      if (!self.inactiveToggles.includes(e.event_type))
      {
        self.activeToggles.push(e.event_type);
      }
    });
  };

  cacheToggledFilters() {
    this.activeToggles = Array
      .from(document.querySelectorAll('input.htimeline_toggle:checked'))
      .map(i => i.dataset.name);

    var tempInactiveToggles = this.inactiveToggles;
    this.inactiveToggles = Array
      .from(document.querySelectorAll('input.htimeline_toggle:not(:checked)'))
      .map(i => i.dataset.name);

    // It's possible for a toggle to be inactive AND not currently rendered on
    // the page. Therefore, we want to preserve all inactive toggles.
    var self = this;
    tempInactiveToggles.forEach(function (toggle) {
      if (!self.activeToggles.includes(toggle)) {
        self.inactiveToggles.push(toggle);
      }
    });
  };

  clearEventDrawings() {
    selectAll("rect.bin").remove();
    selectAll("path.overflowIcon").remove();
    selectAll(".release_event").remove();
  };

  filterToggled() {
    this.cacheToggledFilters();
    this.initEventDict(this.calculateDateRange(this.minViewDate, this.maxViewDate));
    this.populateEventDict();
    this.clearEventDrawings();
    this.plotEvents();
    this.updateVerticalTimeline();
  };

  zoomChanged(newMinDate, newMaxDate) {
    this.minViewDate = newMinDate > this.absMinDate ? newMinDate : this.absMinDate;
    this.maxViewDate = newMaxDate < this.absMaxDate ? newMaxDate : this.absMaxDate;
    var selectedZoom = $('#zoom-dropdown').val();
    this.updateToggledFilters();
    this.initEventDict(this.calculateDateRange(this.minViewDate, this.maxViewDate));
    this.populateEventDict();
    this.generateFilters();
    this.cacheToggledFilters();
    this.clearEventDrawings();
    this.initXAxisScale();
    this.drawXAxis(selectedZoom);
    this.plotEvents();
    this.updateVerticalTimeline();

    if (this.additionalHeight != 0) {
      this.drawResetButton();
    }
  };

  sizeChanged() {
    var selectedZoom = $('#zoom-dropdown').val();
    this.init();
    this.updateToggledFilters();
    this.initEventDict(this.calculateDateRange(this.minViewDate, this.maxViewDate));
    this.populateEventDict();
    this.generateFilters();
    this.cacheToggledFilters();
    this.clearEventDrawings();
    this.initXAxisScale();
    this.drawXAxis(selectedZoom);
    this.plotEvents();
    this.updateVerticalTimeline();
  }

  /**
   * Draw the events on the timeline
   */
  populateTimelines(events, zoomLevels) {
    this.events = events;
    this.minEventDate = this.events[this.events.length - 1].date;
    this.maxEventDate = this.events[0].date;
    this.minViewDate = this.minEventDate;
    this.maxViewDate = this.maxEventDate;

    // If there are event types that should be inactive by default, go
    // ahead and add them to inactiveToggles

    var self = this;
    if(this.events.length > 1500){ // For sparse timelines (< 1500 events), just show everything
      this.events.forEach(function(e) {
        if (e.start_hidden == true && !self.inactiveToggles.includes(e.event_type)) {
          self.inactiveToggles.push(e.event_type);
        }
      });
    }

    this.updateToggledFilters();
    this.cacheToggledFilters();
    this.initXAxisScale();
    this.drawXAxis();

    // Populate the dropdown list
    if ($('#zoom-dropdown option').length == 0) {
      for (var [name, value] of zoomLevels) {
        $('#zoom-dropdown').append($('<option>', {
          value: value,
          text: name
        }));
      }
    }

    var unicode_icon = "🔍";
    var handleUnicode = function() {
      $("#zoom-dropdown option").each(function() {
        // Remove any unicode icons in <option> tags
        $(this).text($(this).text().replace(new RegExp(unicode_icon, 'g'), ''));
      });

      // Add the unicode icon to the correct option
      var selectedOption = $("#zoom-dropdown option:selected");
      selectedOption.text(unicode_icon + " " + selectedOption.text());
    }

    $('#zoom-dropdown').on('change', handleUnicode);

    // When the dropdown is clicked on, remove all icons from the <option> tags
    $('#zoom-dropdown').on('mousedown', function(event) {
      $(this).children("option").each(function() {
        $(this).text($(this).text().replace(new RegExp(unicode_icon, 'g'), ''));
      });

      // After the dropdown has been clicked on, if the user clicks out of the
      // dropdown (i.e., the dropdown is closed), then add the icon back. This
      // handles the situation where the user clicks on the dropdown but then
      // doesn't change the selection.
      $(document).on('mouseup', function(event) {
        // In Firefox, the mouseup event is triggered immediately after the
        // user clicks on the dropdown. When this happens, ignore it. However,
        // the mouseup event will still be registered in case the user later
        // clicks out of the dropdown.
        if ($(event.target).is('#zoom-dropdown'))
          return;

        $(document).off('mouseup');
        handleUnicode();
      });
    });

    $('#zoom-dropdown').val(this.defaultZoomValue);
  }

  // The Event Dictionary is a map of the events by their time column
  //  * Index of the dictionary is the column
  //  * Bucket value is an array (soon to be an array of events)
  // We don't populate the events here just yet - just initializing buckets
  initEventDict(range) {
    this.eDict = {}; // reset from prior updates
    var inc = range / this.numCols;
    for (var i = 0; i < this.numCols; i++) {
      this.eDict[this.addDays(this.minViewDate, i * inc)] = [];
    }
  }

  /**
   * Draw filters below horizontal timeline
   */
  generateFilters() {
    var self = this;
    const reducer = function(hash, e) { // uniq-ify all event types, keeping colors
      if (self.validTypes.includes(e.event_type)) {
        hash[e.event_type.replace(' ','_')] = e.color;
      }
      return hash;
    }

    this.filters = this.events.reduce(reducer, {});
    this.filters['changes'] = '#ccc' //weekly report is weird - multiple colors for one type

    // Clear filters
    select('#toggle-block').selectAll(".htimeline_filter").remove();
    var releaseColor = "#D57BCE"; // Default value for the stroke of a release

    // Repopulate filters
    const filter_span = select('#toggle-block')
                    .selectAll(".htimeline_filter")
                    .data(Object.keys(this.filters))
                    .enter()
                    .append('span')
                      .attr('class', 'htimeline_filter')
                      .attr('style', (f) => `background-color:${this.filters[f]};background-color:${this.filters[f]};border-color: ${this.filters[f]}` );
    filter_span.append('input')
                .attr('type', 'checkbox')
                .attr('id',(f) => f + '-toggle')
                .attr('data-name', (f) => f.replace('_',' '))
                .attr('class','htimeline_toggle')
                .attr('checked','');
    filter_span.append('label')
                .text((f) => f.replace('_',' '))
                .attr('for', (f) => f + '-toggle')
                .on('mouseenter', function (f) {
                  if (f == 'release') {
                    if ($('.' + f + '_event').length) {
                      // Remember the color so that the color change can be
                      // reverted when the mouse leaves
                      releaseColor = $('.' + f + '_event').attr('stroke');
                      selectAll('.' + f + '_event').attr('stroke', '#9933ff');
                    }
                  } else {
                    selectAll('.' + f + '_event').attr('stroke', '#000');
                  }
                })
                .on('mouseleave', function (f) {
                  if (f == 'release') {
                    selectAll('.' + f + '_event').attr('stroke', releaseColor);
                  } else {
                    selectAll('.' + f + '_event').attr('stroke', '');
                  }
                })
                .attr('class',(f)=>`checkbox-label-border-${f}`);

    filter_span.append("style")
                .text((f)=>`
                  .checkbox-label-border-${f}:before {
                    color:${this.filters[f]}!important;
                    border-color:${this.filters[f]}!important;
                  }`
                )

    filter_span.on("click",(e)=>{
      $(`#${e}-toggle`).closest(".htimeline_filter").children("style").remove()
      if ($(`#${e}-toggle`)[0].checked) {
        $(`#${e}-toggle`)
          .closest(".htimeline_filter")
          .attr('style',`background-color:${this.filters[e]};border-color: ${this.filters[e]};`)
          .append(
            `<style>
              .checkbox-label-border-${e}:before {
                color:${this.filters[e]}!important;
                border-color:${this.filters[e]}!important;
              }
              .dark-mode .checkbox-label-border-${e} {
                color:#0a0a0a!important;
              }
            </style>`
          )
      }
      else {
        $(`#${e}-toggle`)
          .closest(".htimeline_filter")
          .attr('style',`background-color:transparent;border-color: ${this.filters[e]};`)
          .append(
            `<style>
              .checkbox-label-border-${e}:before {
                color:${this.filters[e]}!important;
                border-color:${this.filters[e]}!important;
              }
              .dark-mode .checkbox-label-border-${e} {
                color:${this.filters[e]}!important;
              }
            </style>`
          )
      }
    })

    // Unchecks toggles according to previous inactiveToggles
    this.inactiveToggles.forEach(function(t) {
      $('#' + t.replace(' ','_') + '-toggle')
        .attr('checked',false)
        // .attr('style',`color:${self.filters[t.replace(' ','_')]}`)
        .closest(".htimeline_filter")
        .attr('style',`background-color:transparent;border-color: ${self.filters[t.replace(' ','_')]};`)
        .append(
          `<style>
            .dark-mode .checkbox-label-border-${t.replace(' ','_')} {
              color:${self.filters[t.replace(' ','_')]}!important;
            }
          </style>`
        )
    });
  }

  /**
   * Populate ONLY events within date range to eDict column with closest date.
   */
  populateEventDict() {
    for (var i = 0; i < this.events.length; i++) {
        var e = this.events[i];
        if (this.shouldDrawEvent(e)) {
            var col = this.getCol(e.date);
            this.eDict[col].unshift(e);
        }
    }
  };

  /**
   *  Should we draw this event?
   *   - Is it within the min/max dates?
   *   - Is it in the "active" filters?
   */
  shouldDrawEvent(e) {
    return (this.activeToggles.includes(e.event_type))
        && (e.date >= this.minViewDate)
        && (e.date <= this.maxViewDate);
  };

  /**
   *  Should we draw this toggle/filter?
   *   - Is it within the min/max dates?
   */
  shouldDrawToggle(e) {
    return (e.date >= this.minViewDate)
        && (e.date <= this.maxViewDate);
  };

  /**
   * Update vertical timeline based on toggled filters and
   * the active dates (depends on zoom level)
   */
  updateVerticalTimeline() {
    var self = this; // 'this' will change when we enter forEach
    this.events.forEach(function(e) {
      var prefix = e.event_type == 'release' ? 'release' : 'event';
      if (self.shouldDrawEvent(e)) {
        $(`#${prefix}_block_${e.id}`).fadeIn();
        $(`#${prefix}_block_${e.id}`).addClass("show-block");
      } else {
        $(`#${prefix}_block_${e.id}`).fadeOut();
        $(`#${prefix}_block_${e.id}`).removeClass("show-block");
      }
    });
    const showBlocks = document.querySelectorAll(".show-block");
    showBlocks.forEach((block,i)=>{
      $(block).removeClass("block-right");
      if (i % 2 !== 0) {
        $(block).addClass("block-right");
      }
    })
  };

  //helper methods

  /**
   * Given a string d, create a date
   * @param d - json format date string
   * @returns {Date} a JS date item
   */
  getDate(d) {
    if (!Number.isInteger(d)) {
        d = d.toString().replace(/\s[A-Za-z]{3}/g, "Z").replace(/ /g, "T");
    }
    return new Date(d);
  }

  /**
   * Adds a number of days to a given date
   * @param date - the starting date
   * @param days - the number of days to add
   * @returns {Date} A date + days
   */
  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + Math.floor(days));
    var minutes = (days % 1) * 1440; // Gets the decimal from days and converts it into minutes
    result.setMinutes(result.getMinutes() + Math.floor(minutes));
    return result;
  };

  /**
   * Calculates the range between two dates
   * @param a - the start date
   * @param b - the end date
   * @returns {number} the absolute number of days between the dates
   */
  calculateDateRange(a, b) {
    var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.abs(Math.floor((utc2 - utc1) / (86400000)));
  };

  /**
   * Go through columns and pick which column the event goes into based on
   * closest date
   * @param date - a js date object
   * @returns {Date} - the date closest to the date provided in the js object which
   * corresponds to the key in the eDict
   */
  getCol(date) {
    var cols = Object.keys(this.eDict);
    var c = new Date(cols[0]);
    if (date && cols) {
        for (var i = 1; i < cols.length; i++) {
            if (this.calculateDateRange(new Date(cols[i]), date) < this.calculateDateRange(c, date)) {
                c = new Date(cols[i]);
            }
        }
    }

    return c;
  };

  /**
   * plot the events as chiclets on the horizontal timeline. any events exceeding
   * the column limit will be shown in overflow
   */
  plotEvents() {
    var cols = Object.keys(this.eDict);
    var tipId = 0;
    var tip;
    var self = this;
    cols.forEach(function(key) {
        var eArr = self.eDict[key];
        var colOverFArr = [];
        var yIndex = 0;
        var releaseIndex = 0;
        for (var i = 0; i < eArr.length; i++) {
            var event = eArr[i];
            tip = self.renderToolTips(tip, tipId);
            tipId += 1;
            if (yIndex < self.chicLimit) {
                if (event.event_type == 'release') {
                    self.drawFlag(tip, new Date(key), releaseIndex, event);
                    releaseIndex++;
                } else {
                    self.drawChic(tip, new Date(key), yIndex, event);
                    yIndex++;
                }
            } else {
                colOverFArr.push(event);
            }
        }
        if (colOverFArr.length > 0) {
            self.drawOverflow(tip, new Date(key), colOverFArr);
        }
    });
  };

  /**
   * Render the tooltips on the horizontal timeline
   *
   * @param tip
   * @param index
   * @returns {*}
   */
  renderToolTips(tip, index) {
    tip = select("#tooltips").append("div") // render tooltips
        .attr("class", "tooltip")
        .attr("id", index)
        .style("display", "none")
        .on('mouseenter', function () {
            tip.transition().duration(0);
        });

    return tip;
  };

  showOverflowHover(events) {
    $('#legend-body').html(
        '<p><b>More Events:</b></p> <ul></ul>'
    );
    events.forEach(function(event, index) {
      const event_date = new SimpleDate(event.date).longFormat();
      $('#legend-body ul').append(`
        <li>
        <p class="legend-desc">${event.title}</p>
        <p class="legend-date">${event_date}</p>
        <hr></li>
      `);
    });
  };

  showTooltip(tip, event) {
    const event_date = new SimpleDate(event.date).longFormat();
    $('#legend-body').html(`
      <i class="material-icons legend-icon"
          style="background-color: ${event.color}">
          ${event.icon}
      </i>
      <div>
        <p class="legend-desc">${event.title}</p>
        <p class="legend-date">${event_date}</p>
      </div>
    `);

    // Ensure that the legend is at least as tall as the icon it contains
    if ($('.legend').height() < $('.legend-icon').first().height()) {
      $('.legend').height($('.legend-icon').first().height());
    }
  };

  drawFlag(tip, col, releaseIndex, event) {
    var flagHeight = this.xAxisHeight / 2;
    var flagWidth = 3;
    var thisX = this.x(col);
    var startX = thisX + (releaseIndex * (flagWidth + 1));
    if ((releaseIndex * (flagWidth + 1)) > this.chicWidth - flagWidth) {
      return;
    }
    var startY = this.svgHeight - this.xAxisHeight;
    var self = this;
    this.svg.append("svg:line")
        .attr("class", "bin link_to_event release_event")
        .attr("x1", startX)
        .attr("y1", startY)
        .attr("x2", startX)
        .attr("y2", startY + flagHeight)
        .attr("stroke-width", flagWidth)
        .attr("stroke", event.color)
        .attr("fill", event.color)
        .on("click", function () { // link to horizontal-timeline
            window.location.href = '#release_' + event.id;
        })
        .on("mouseenter", function () { // show chiclet
            $(this)
              .attr("stroke", "#9933ff")
              .attr("fill", "#9933ff");
            self.showTooltip(tip, event);
        })
        .on("mouseleave", function () { // hide tooltip
            $(this)
              .attr("stroke", event.color)
              .attr("fill", event.color);
            tip.transition().style("display", "none");
        });
  };

  drawChic(tip, event_date, yIndex, event) {
    var self = this;
    this.svg.append("svg:rect") // draw the chiclets
        .attr("class", "bin link_to_event " + event.event_type.replace(' ', '_') + "_event")
        .attr("x", function () {
            // map through d3's scaleTime(), center on chiclet
            return self.x(event_date);
        })
        .attr("y", function () {
            // map through d3's scaleLinear()
            // +6 to adjust for a gap
            return self.y(yIndex) + 6;
        })
        .attr("width", self.chicWidth - 3) // adjust for gap
        .attr("height", self.chicHeight - 2) // ajdust for a gap
        .attr("rx", self.iconCornerRadius)
        .attr("ry", self.iconCornerRadius)
        .attr("fill", event.color)
        .on("click", function () { // link to horizontal-timeline
            window.location.href = '#event_' + event.id;
        })
        .on("mouseenter", function () { // show chiclet
          $(this).attr("stroke", "#000");
            self.showTooltip(tip, event, window, this);
        })
        .on("mouseleave", function () { // hide tooltip
            $(this).attr("stroke", "");
            tip.transition().style("display", "none");
        });
  };

  /**
   * Increases the height of the timeline to show more events. The height
   * is increased 150% relative to the original height. Also adds a "reset"
   * button to reset the height.
   */
  showMoreEvents() {
    this.additionalHeight += this.timelineHeight / 2;
    this.sizeChanged();
    this.drawResetButton();
  }

  /**
  * Draws the reset button that resets the timeline to the default height
  */
  drawResetButton() {
    var button = select('#reset-button').show();
    var icon = select('#reset-icon').show();

    var self = this;
    button.on('click', function() {
      self.additionalHeight = 0;
      button.hide();
      icon.hide();
      self.sizeChanged();
    });
  }

  drawOverflow(tip, col, events) {
    var cH2 = this.chicHeight / 2;
    var startX = this.x(col) + (this.chicWidth / 2);
    var vertLine = "M" + startX + " " + cH2 + " V " + (this.chicHeight + cH2);
    var horLine = "M" + (startX - cH2) + " " + (this.chicHeight) + " H " + (startX + cH2);
    var self = this;

    this.svg.append("path")
      .attr("d", vertLine + horLine) // combines both lines to form a + symbol
      .attr("stroke-width", 3)
      .attr("stroke", "#808080")
      .attr('class', 'overflowIcon')
      .on('click', function(d) {
        self.showMoreEvents();
      })
      .on('mouseenter', function() { // show chiclet
        $(this).attr("stroke", "#9933ff")
          .attr("fill", function() {
            return "#9933ff";
          });

        // Display an overview of the overflowed events
        self.showOverflowHover(events);

        // Show tooltip
        var tooltipY = cH2 + self.chicHeight + 10; // + 10 to account for cursor
        var textXOffset = 5 // positions the text inside the rect horizontally
        var gTextBox = self.svg.append("g")
          .attr('id', 'overflow-tooltip');
        var rectTextBox = gTextBox.append("rect")
          .attr('rx', self.iconCornerRadius)
          .attr('ry', self.iconCornerRadius)
          .attr('x', startX)
          .attr('y', tooltipY);
        var textBox = gTextBox.append("text")
          .attr('y', tooltipY + 18); // + 18 to position the text inside the rect
        var topText = textBox.append("tspan")
          .text("Click here to see a")
          .attr('x', startX + textXOffset);
        var bottomText = textBox.append("tspan")
          .text("few more events.")
          .attr('x', startX + textXOffset)
          .attr('dy', 18);

        // Resize the rect to be slightly larger than the text inside of it
        var bBox = gTextBox.node().getBBox();
        rectTextBox.attr('height', bBox.height + 10)
          .attr('width', bBox.width + 10);

        // Reposition the rect and text if they extend past the SVG
        var tooltipOverflow = startX + bBox.width + 15 - self.svgWidth;
        if (tooltipOverflow > 0) {
          rectTextBox.attr('x', rectTextBox.attr('x') - tooltipOverflow);
          topText.attr('x', topText.attr('x') - tooltipOverflow);
          bottomText.attr('x', bottomText.attr('x') - tooltipOverflow);
        }
      })
      .on('mouseleave', function() { // hide tooltip
        $(this).attr("stroke", "#808080");
        tip.transition().style("display", "none");
        self.svg.select("#overflow-tooltip").remove();
      });
  };

  /**
   * Function used to display type labels as some other type if necessary
   * @param type the current data type
   * @returns {*} a new type, what should be displayed
   */
  renameTypeLabel(type) {
    switch (type) {
        case "commit_filepath":
            return "edit";
            break;
        default:
            return type.replace('_',' ');
            break;
    }
  };

  /**
   * Title casing function
   * @param str - string to title case
   * @returns String - title cased string
   */
  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
