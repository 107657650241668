import $ from 'jquery'

/**
 * Define behavior around the "scroll to top" button
 * See vulnerabilities/show.html.erb as an example of how to put in the "scroll to top" link
 */
export default function bindScrollToTopEvents() {
    $('.scroll-to-top').click(function () {
        $('html, body').animate({scrollTop: 0}, 600);
        return false;
    });

    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('.scroll-to-top').fadeIn();
            // $('.scroll-to-top').css('display','flex');
        } else {
            $('.scroll-to-top').fadeOut();
        }
    });
}
