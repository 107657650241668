import $ from 'jquery'
import articleListingOneSize from '../global/articleListing'

function load_succeded(data) {
  $("#loading").hide();

  var cves = [];
  var all;
  var filepaths = [];

  all = data;
  for (var i = 0; i < data.length; i++) {
    if (data[i]["filepaths"].length != 0) {
      filepaths.push(data[i]);
    }
    if (data[i]["cves"].length != 0 && data[i]["cves"][0] != null) {
      cves.push(data[i]);
    }
  }

  articleListingOneSize(all, "#article-listing-same");
  articleListingOneSize(filepaths, "#article-listing-files");
  articleListingOneSize(cves, "#article-listing-cves");

  // search filter
  var filter = document.getElementById("search-filter");
  filter.onkeyup = () => {
    let search = filter.value.toLowerCase();

    // for loop for articles on page
    var articles = document.getElementsByClassName("vhp-articles");
    var separators = document.getElementsByTagName("hr");
    for (let i = 0; i < articles.length; i++) {
      // indexOf is faster on chrome, but Regex test() is another option
      if (
        articles[i]
          .getElementsByClassName("blurb-text")[0]
          .innerText.toLowerCase()
          .indexOf(search) === -1
      ) {
        // remove element from view and separator under it
        articles[i].style.display = "none";
        if (separators[i]) {
          separators[i].style.display = "none";
        }
      } else {
        articles[i].style.display = "block";
        if (separators[i]) {
          separators[i].style.display = "block";
        }
      }
    }
  };

  // ever-changing serach filter suggestions
  filter.placeholder = languageInfo();
}

function languageInfo() {
  const searchSuggestions = [
    "inputs",
    "VCC",
    "refactoring",
    "malloc",
    "developer",
    "project",
    "vulnerability",
    "code",
    "mistake",
  ];
  return `Try "${_.sample(searchSuggestions)}"`;
}

function load_failed(data) {
  $("#loading").hide();
  $("#loading-failed").show();
}

export default function onArticlesIndex(){
  $.ajax({
    url: "/api/articles",
    dataType: 'json',
  }).then(load_succeded, load_failed)
}

