import $ from 'jquery'
import { friendlyCWE as friendlyCWE } from '../global/dataToPrettyStrings'
import SimpleDate from '../global/simpleDate'

export function vulnCards(jsonData, tagMap, container = '#vuln_cards'){
  for (let v of jsonData) {
    let card = $('#vuln_card_template').clone();
    card.attr('id', `vuln-card-${v.id}`);
    $(container).append(card);
    populateVulnCard(v, tagMap, '#' + card.attr('id'));
  }
}

/**
 * Populate a single vulnerability card in the DOM.
 * @param {*} vulnerability JSON data of the vulnerability
 * @param {*} tagMap map to look up tags for the vulnerability
 * @param {*} container JQuery lookup of element in DOM to populate
 */
function populateVulnCard(vulnerability, tagMap, container) {
  let card = $(container);
  card.attr('href', `/vulnerabilities/${vulnerability.id}`);
  if(vulnerability.nickname.trim() != '') {
    card.find('.title-space').append(`
      <div class="title">
        <span class="${vulnerability.subdomain}-inline-logo"></span>
        ${vulnerability.nickname}
      </div>
      <div class="subtitle">${vulnerability.cve}</div>
    `);
  } else {
    card.find('.title-space').append(`
      <div class="title-only">
        <span class="${vulnerability.subdomain}-inline-logo"></span>
        ${vulnerability.cve}
      </div>
    `);
  }

  card.find('.date')
      .attr('title', vulnerability.announced)
      .html(new SimpleDate(vulnerability.announced).shortFormat());
  if(vulnerability.upvotes > 0) {
    card.find('.upvote-number').html(vulnerability.upvotes);
    card.find('.upvotes i')
        .attr('title', `${vulnerability.upvotes} upvotes`)
        .attr('class', 'vhp-icon-upvote');
  }
  for(let t of vulnerability.tag_json){
    const tag = tagMap[t.id]
    if(tag.name.startsWith('CWE')){
      card.find('.cwe').html(friendlyCWE(tag.name.split(':')[1].trim()));
    }
  }
  for(let t of vulnerability.tag_json){
    const tag = tagMap[t.id]
    if(in_tag_list(tag.name)){
      card.find('.icons').append(`
        <i title="${tag.name}"
           class="vhp-icon-${tag.icon}"
           style="color: ${tag.color}" ></i>
      `)
     }
  }
}

function in_tag_list(name) {
  return !name.startsWith('CWE')
      && !name.startsWith('Project:')
}
