import $ from 'jquery'
import 'datatables.net'
import 'datatables.net-responsive-dt'
import 'datatables.net-zf'
import { hideSinglePagePagination as hideSinglePagePagination } from '../global/datatablesTweaks'
import {vulnLink, vulnProject, vulnName} from '../global/dataToPrettyStrings'
import SimpleDate from '../global/simpleDate'
import setTooltip from '../vulnerabilities/tooltip'

function columnsInfo() {
  return [
    {
      title: '<i class="vhp-icon-tag"></i>Vulnerability',
      data: null,
      defaultContent: "",
      width: "7em",
      class: "text-center",
      responsivePriority: 1,
      render: (data) => vulnLink(data, vulnProject(data) + vulnName(data)),
    },
    {
      title: "Description",
      data: "short_desc",
      defaultContent: "",
      class: "text-left",
      responsivePriority: 3,
    },
    {
      title: "Curator Note",
      data: "tag_note",
      class: "text-left",
      defaultContent: "",
      responsivePriority: 4,
    },
    {
      title: "Announced",
      data: null,
      defaultContent: "",
      class: "text-center",
      responsivePriority: 5,
      render: (data) =>
        vulnLink(data, new SimpleDate(data.announced).shortFormat()),
    },
    {
      title: "Upvotes",
      data: "upvotes",
      defaultContent: "",
      class: "text-center",
      responsivePriority: 2,
    },
  ];
}

function load_succeded(jsonData) {
  // There is a glitch when manipulating the data using vulnLink. The link to the cve
  // needs to be based on the vulnerability_id. This is a quick patch to fix the links.
  for (var index in jsonData) {
    let tagID = jsonData[index].id;
    let vulnID = jsonData[index].vulnerability_id;

    jsonData[index]['id'] = vulnID;
    jsonData[index]['tag_id'] = tagID;
    jsonData[index]['vulnerability_id'] = undefined;
  }
  $('#datatable').dataTable({
    responsive: true,
    destroy: true,
    data: jsonData,
    order: [[4, "desc"]] /* Default sort */,
    columns: columnsInfo(),
    columnDefs: [
      { width: "16%", targets: 0 },
      { width: "40%", targets: 1 },
    ],
    preDrawCallback: (settings) => hideSinglePagePagination(settings),
    initComplete: function () {
      $("#loading").remove();
    },
  });
  // makes all external links open in new tab
  let links = document.getElementsByTagName("a");
  for (var i = 0; i < links.length; i++) {
    if (
      !links[i].href.match("^/") &&
      !links[i].href.match(window.location.host)
    ) {
      links[i].target = "_blank";
      links[i].setAttribute("rel", "noopener noreferrer");
    }
  }
}

function load_failed(jsonData) {
  $("#loading").hide();
  $("#loading-failed").show();
}

export default function onTagsShow() {
  $.ajax({
    url: "/api/tags/" + tag_id,
    dataType: "json",
  }).then(load_succeded, load_failed);

  $("#datatable").on("mouseover", "tbody tr", function () {
    $(this).css({
      cursor: "pointer",
      color: "blue",
    });
  });

  $("#datatable").on("mouseout", "tbody tr", function () {
    $(this).css({
      cursor: "default",
      color: "inherit",
    });
  });

  setTooltip('#datatable', '.dt_row');
}
