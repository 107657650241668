import {select, scaleSequential, interpolateRgbBasis, interpolate, pie as d3Pie, arc as d3Arc, selectAll} from 'd3';
import _ from 'lodash';


export function buildDevPie(data, domID = "#dev-pie-chart") {
  const personShadow_dpath = "m 67.0605,0 c -9.389242,0 -18.091528,3.568427 -24.600065,10.067446 -6.507034,6.499019 -10.541524,15.86477 -10.541524,26.084621 0,10.843976 4.748524,20.426774 12.007198,26.999844 l 5.271013,4.5769 -7.614187,1.37283 C 21.38541,72.697101 11.741943,80.182641 6.1490135,91.754181 0.94268491,102.52685 0.12485561,117.38511 0,135 h 135 c -0.0236,-17.68933 -0.17549,-32.93468 -4.97818,-43.933019 -5.15468,-11.80465 -14.60259,-19.33408 -36.312416,-22.65176 l -7.61469,-1.14402 4.978177,-4.80571 c 6.806385,-6.53663 11.128189,-15.851835 11.128189,-26.313424 0,-10.219851 -4.035484,-19.585602 -10.543024,-26.084621 C 85.150524,3.568427 76.448739,3.92e-4 67.059497,3.92e-4 Z";

  var width = 650,
      height = 650,
      radius = 0.95 * Math.min(width, height) / 2; // 95% for scale transform
  var svg = select(domID)
              .append("svg:svg")
                // svg width and height NOT set for responsiveness here!!
                // viewBox is the internal coordinate system and then this
                // scales to fit its container
                .attr("viewBox", "0 0 " + width + " " + height)
                .attr("preserveAspectRatio", "xMidYMid meet")
              .append("g")
                 .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
  // spectrum = interpolateRgbBasis(['#C2D7B6','#3A6024','#C2D7B6']) // green
  // spectrum = interpolateRgbBasis(['#FFCA5F','#8F5F00','#FFCA5F']) // orange
  const spectrum = interpolateRgbBasis(['#708CCB','#1A3B87','#708CCB']) // blue
  var color = scaleSequential(spectrum).domain([0,data.length])

  var pie = d3Pie()
              .value(d => d.num_commits)
              .sort(null);

  var arc = d3Arc()
              .innerRadius(radius / 1.8)
              .outerRadius(radius);

  toggled('num_commits'); // initially set to this

  svg.append('a')
     .attr('class', 'dev-pie-link')
     .attr('title','About this dev')
     .append('path')
       .attr('transform', 'translate(-67.5,-135)')
       .attr('id', 'dev-pie-person-shadow')
       .attr('d', personShadow_dpath)
       .attr('fill', '#fff');
  const devPieInfo = svg.append('text')
                        .attr('x', 0)
                        .attr('y', '.5em')
                        .attr('text-anchor', 'middle')
                        .attr('width', radius / 3.25)
                        .attr('height', radius / 3.25)
                        .attr('id','dev-pie-info')
                        ;
  devPieInfo.append('a')
            .attr('class', 'dev-pie-link')
            .attr('title','About this dev')
            .append('tspan')
              .attr('id', 'dev-pie-nickname')
              .attr('x', '0')
              .attr('dy', '1.2em')
            ;
  devPieInfo.append('tspan')
            .attr('id', 'dev-pie-commits')
            .attr('x', '0')
            .attr('dy', '1.2em')
            ;
  devPieInfo.append('tspan')
            .attr('id', 'dev-pie-churn')
            .attr('x', '0')
            .attr('dy', '1.2em')
            ;
  svg.append('text')
     .attr('id', 'dev-pie-info-inserted')
     .attr('text-anchor', 'end')
     .attr('x', -105)
     .attr('y', 90)
     ;
  svg.append('text')
     .attr('id', 'dev-pie-info-deleted')
     .attr('text-anchor', 'start')
     .attr('x', 105)
     .attr('y', 90)
     ;
  svg.append('rect')
      .attr('x', -100)
      .attr('y', 90)
      .attr('width', 200) // this gets reset each hover
      .attr('height', 25)
      .attr('fill', '#fff')
      .attr('id','dev-pie-commitvis-inserted')
      ;
  svg.append('rect')
      .attr('x', -100) // this gets reset each hover
      .attr('y', 90)
      .attr('width', 200) // this gets reset each hover
      .attr('height', 25)
      .attr('fill', '#fff') // this gets reset each hover
      .attr('id','dev-pie-commitvis-deleted')
      ;

  selectAll("#dev-pie-toggle input")
    .on("change", toggled);

  function toggled(val = this.value) {
    pie.value((d) => d[val]);  // set to num_commits, total_churn, etc.
    const path = svg.selectAll('path')
                    .data(pie(data));
    path.enter()
        .append('path')
          .attr('d', arc)
          .attr('id', (_d, i) => "dev_pie_path_" + i)
          .attr('fill', (_d, i) => color(i))
          .attr('stroke', '#fff')
          .attr('stroke-width', '0.2em')
          .attr('class', 'dev-pie-slice')
          .each(function(d) { this._current = d; })
          .on("mouseover", mousedOver)
          .on("mouseout", mousedOut)
          .on("click", mousedOver)
          ;
    path.transition().duration(750).attrTween("d", arcTween);
  }

  function mousedOver(_event, d){
    select('#dev-pie-nickname').text(d.data.nickname);
    select('#dev-pie-commits').text(d.data.num_commits + " commits");

    const churn_text = d.data.total_churn + ' lines edited';
    select('#dev-pie-churn').text(churn_text);
    select('#dev-pie-person-shadow').attr('fill',color(d.index));
    select("#dev_pie_path_" + d.index).attr('transform', 'scale(1.05)')
    selectAll('.dev-pie-link').attr('href', '/developers/' + d.data.id)
    selectAll('#dev-pie-info-inserted').text("+" + d.data.insertions);
    selectAll('#dev-pie-info-deleted').text("-" + d.data.deletions);
    //churn visualization [+++ | ---]
    const perc_insert = _.clamp(d.data.insertions / d.data.total_churn, 0, 1.0)
    const midpoint = (200 * perc_insert)
    const gap_between = 2
    selectAll('#dev-pie-commitvis-inserted')
      .attr('width', midpoint )
      .attr('fill', '#4AB808' )

    selectAll('#dev-pie-commitvis-deleted')
      .attr('x', -100 + midpoint + gap_between )
      .attr('width', 200 - midpoint - gap_between )
      .attr('fill', '#D32009' )
  }

  function mousedOut(_event, d){
    select("#dev_pie_path_" + d.index).attr('transform', '') ;
  }

  // Store the displayed angles in _current.
  // Then, interpolate from _current to the new angles.
  // During the transition, _current is updated in-place by d3.interpolate.
  function arcTween(a) {
    var interp = interpolate(this._current, a);
    this._current = interp(0);
    return function(t) {
      return arc(interp(t));
    };
  }

}
