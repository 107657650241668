import $ from 'jquery'
import OffenderMap from './offender_map'
import OffenderTable from './offender_table'

function load_succeded(offenders_response, projects_response) { // once both ajax calls are done
  $('#loading_map').hide();

  const offenderMap = new OffenderMap().init();

  let projects = projects_response[0]
  projects.forEach(p => {
    $('.project-choice').append(`<option value=${p.id}>${p.name}</option>`);
    $('.project-choice-table').append(`<option value=${p.id}>${p.name}</option>`);
  });

  let offenders = offenders_response[0]
  offenderMap.setData(offenders, projects)

  // building table after map because the page defaults to Map first.
  // We COULD build this in parallel with the map, or prioritize depending on
  // which was chosen: filepaths#map or filepaths#table
  // For now, these are both pretty fast anyway so it's not noticeable.
  new OffenderTable(offenders, projects).init(); // For now, doing in sequence - could change to parallel later
}

function load_failed(offenders_response, projects_response) {
  $('#loading_map').hide();
  $('#loading_map_failed').show();
  $('#loading').hide();
  $('#loading-failed').show();
}

export default function onFilepathsIndex(){
  const filepath_url = "/api/filepaths?offenders=true";
  const project_url = "/api/projects";
  $.when(
    $.ajax({dataType: 'json', url: filepath_url }),
    $.ajax({dataType: "json", url: project_url })
  ).then(load_succeded, load_failed);
}
