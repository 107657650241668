import $ from 'jquery'
import { select, scaleTime, scaleLinear, selectAll } from 'd3';
import SimpleDate from '../global/simpleDate'

/**
 * Object representation of the punchcard used to show levels of activity
 * (e.g., a developer's commit activity)
 *
 * @param chicHeight (int) - height of a chiclet in pixels
 * @param chicWidth (int) - width of a chiclet in pixels
 * @param minDate (Date) - absolute minimum date from all activities
 * @param maxDate (Date) - absolute maximum date from all activities
 * @constructor
 */
export default function punchcard(settings) {
    this.separatorHeight = 3;
    this.chicGap = 1;
    this.colors = ["lightgray", "#99bd99", "#5f9c4c", "#005c00"];
    this.chicHeight = settings.chicHeight;
    this.chicWidth = settings.chicWidth;
    this.min = settings.minDate;
    this.max = settings.maxDate;
    this.svg;
    this.x;
    this.y;

    /**
     * Initialize the SVG, but don't draw events yet.
     */
    this.init = function() {
        this.removeSVG();
        this.initSVG();
        this.addLegend();
        this.fadeInSVG();
    };

    this.removeSVG = function() {
        select("#punchcard").remove();
        select("#legend").remove();
        select("#year-labels").remove();
    };

    this.initXAxisScale = function() {
        this.x = scaleTime()
                   .domain([1, 53])
                   .range([0, this.svgWidth - this.chicWidth]);
    };

    this.initYAxisScale = function() {
        var delta = this.max.getFullYear() - this.min.getFullYear();
        var maxDomain = delta * this.separatorHeight;
        this.y = scaleLinear()
                   .domain([0, maxDomain])
                   .range([0, this.svgHeight - this.chicHeight]);
    };

    this.initSVG = function() {
        // Tooltip: displays date and # of activities of each chiclet
        select('#horizontal-punchcard').append("div")
            .attr("id", "tooltip")
            .style("visibility", "hidden");

        var totalYears = this.max.getFullYear() - this.min.getFullYear() + 1;
        var pxPerYear = this.chicHeight + this.separatorHeight;

        var textGroup = select('#horizontal-punchcard').append("g")
            .attr("id", "year-labels")
            .attr("x", "0")
            .attr("y", "0")
            .attr('height', (totalYears * pxPerYear) + "px");

        // Add a temporary test label to calculate size of year labels
        textGroup.append("text")
            .attr("id", "test-label")
            .style("visibility", "hidden")
            .style("font-size", this.chicHeight + "px")
            .text("0000");
        var labelWidth = $('#test-label').width();
        select("#test-label").remove();

        // Add a label in front of the punchcard for each year in the range
        for (let year = this.min.getFullYear(); year <= this.max.getFullYear(); year++) {
            textGroup.append("text")
                .attr("class", "year-label")
                .style("font-size", this.chicHeight + "px")
                .style("margin-top", "-3.4px") // Bad: hardcoded position
                .attr("width", labelWidth + "px")
                .text(year);
        }

        var punchcardWidth = $('#horizontal-punchcard').width();
        this.svg = select('#horizontal-punchcard').append("svg")
            .attr("id", "punchcard")
            .attr('opacity', 0)
            // SVG width is dynamic, fills remaining space minus 10px margin
            // SVG height is hardcoded such that all years are displayed
            .attr('width', (punchcardWidth - labelWidth - 10) + "px")
            .attr('height', (totalYears * pxPerYear) + "px");

        this.svgWidth = parseInt(this.svg.style('width')); // compute px
        this.svgHeight = parseInt(this.svg.style('height')); // compute px
        this.initXAxisScale();
        this.initYAxisScale();
    };

    // Add a legend to the bottom right of the punchcard
    this.addLegend = function() {
        var self = this;

        var legend = select('#horizontal-punchcard').append("g")
            .attr("id", "legend")
            .attr('opacity', 0)
            .attr('width', "100%")
            .attr('height', self.chicHeight + "px")
            .attr('style', "display: flex; float: right;");

        legend.append("p")
            .attr('height', self.chicHeight + "px")
            .style("font-size", this.chicHeight + "px")
            .html("fewer");

        // Will contain chiclets of each color, including light gray.
        var chics = legend.append("svg")
            .attr('width', self.chicWidth * self.colors.length + "px")
            .attr('height', self.chicHeight + "px")
            .attr('style', "margin: 2 5;");


        // Create a chiclet for every color in self.colors
        for (var i = 0; i < self.colors.length; i++) {
            chics.append("svg:rect")
                .attr("y", 0)
                .attr("x", self.chicWidth * i)
                .attr("width", self.chicWidth - self.chicGap)
                .attr("height", self.chicHeight - self.chicGap)
                .attr("fill", self.colors[i]);
        }


        legend.append("p")
            .attr("height", self.chicHeight + "px")
            .style("font-size", this.chicHeight + "px")
            .html("more");
    };

    // Do a CSS fade in
    this.fadeInSVG = function() {
        selectAll('#horizontal-punchcard svg')
          .transition()
          .duration(750)
          .attr('opacity', 1);
    };

    // Do a CSS fade out
    this.fadeOutSVG = function() {
        selectAll('#horizontal-punchcard svg')
          .transition()
          .duration(750)
          .attr('display', "none");
    };

    this.drawChic = function(dateRange, count = 0, interval) {
        var self = this;
        this.svg.append("svg:rect") // draw the chiclets
            .attr("class", "bin")
            .attr("x", function () {
                // Get the week of the year.
                var xDate = new SimpleDate(dateRange.split(" to ")[0]);
                var xValue = xDate.getWeekOfYear();
                // In the event of a wrap-around (like 12/26/2010 being counted
                // as in the first week of 2011), ensure wrap-arounds get
                // displayed in the 53rd week.
                if (xValue == 1 && xDate.getDayOfMonth() > 7) {
                    xValue = 53;
                }
                // map through d3's scaleTime(), center on chiclet
                return self.x(xValue);
            })
            .attr("y", function () {
                let date = new SimpleDate(dateRange.split(" to ")[0]);
                // map through d3's scaleLinear()
                // each year will be visually separated from the others
                let delta = date.getYear() - self.min.getFullYear();
                return self.y(delta * self.separatorHeight);
            })
            .attr("width", self.chicWidth - self.chicGap) // adjust for gap
            .attr("height", self.chicHeight - self.chicGap) // ajdust for gap
            .attr("fill", function() {
                // Calculate which color the chiclet will be filled in with
                let colorIndex = 0;
                if (count > 0) {
                    colorIndex = Math.ceil(count / interval) - 1;
                    colorIndex = colorIndex == 0 ? 1 : colorIndex;
                }
                return self.colors[colorIndex];
            })
            .on("click", function () {
                // When the user clicks on a punchcard chiclet, filter the
                // results of the datatable (if it exists) by the range of dates
                if ($('#datatable').length > 0) {
                    let start = new SimpleDate(dateRange.split(" to ")[0]);
                    let end = new SimpleDate(dateRange.split(" to ")[1]);

                    // Create regex string with all dates within the start and end dates
                    let searchQuery = "(";
                    for (let i = start; end.isDateSameOrAfer(i); i.addTime(1, 'days')) {
                        searchQuery += i.shortFormat();
                        searchQuery += " | ";
                    }
                    searchQuery = searchQuery.slice(0,-3) + ")";

                    // Put the query into the search field
                    $('#datatable').dataTable()
                        .api()
                        .search(searchQuery)
                        .draw();

                    // Trigger an input event on the search field
                    // so that the regex feature is turned on
                    $('#datatable_wrapper input').trigger('input');
                }
            })
            .on("mouseover", function (event) {
                select(this).attr("stroke", "#000");
                select('#tooltip')
                    .style("visibility", "visible");
                select('#tooltip')
                    .html(dateRange + "<br>" + count + " commit" + (count == 1 ? "" : "s"))
                    .style("left", (event.pageX - 5) + "px")
                    .style("top", (event.pageY + 15) + "px");
            })
            .on("mouseout", function () {
                select(this).attr("stroke", "");
                select('#tooltip')
                    .style("visibility", "hidden");
            });
            
    };

    // Parameter: dateRanges is a key-value pair object where the keys are
    // of the format "<start date> - <end date>" and the values are the
    // number of activities that occurred during that date range (inclusive).
    this.plotActivities = function(dateRanges) {
        var self = this;

        // Determine max number of commits to color the punchard accordingly
        var highestValue = 0;
        var keys = Object.keys(dateRanges);
        for (var i = 0; i < keys.length; i++) {
            if (dateRanges[keys[i]] > highestValue) {
                highestValue = dateRanges[keys[i]];
            }
        }
        var colorInterval = highestValue / self.colors.length;

        for (let range in dateRanges) {
            self.drawChic(range, dateRanges[range], colorInterval);
        }
    }
}
