import showdown from "showdown";
import sanitizeHtml from "sanitize-html";

export function vhpMarkdownAll() {
  for (let e of document.querySelectorAll(".vhp-markdown")) {
    e.innerHTML = vhpMarkdown(e.textContent.trim());
  }
}

// VHP-Flavored Markdown
//
//  * CVE identifiers link to the CVE database
//  * Icons inside of colons get converted to <i> tags.
//  * Markdown syntax (URLs are auto-converted to an <a> tag)
//  * Sanitize goes over the HTML code to remove anything XSS-related
//
export function vhpMarkdown(str) {
  str = linkifyCVE(str);
  str = embedVHPIcons(str);
  let converter = new showdown.Converter({
    simplifiedAutoLink: true, // find any URLs and link em
  });
  str = converter.makeHtml(str);
  str = cleanHtml(str);
  return str;
}

// Autolink all vulnerabilities following the format CVE-####-#### (case insensitive).
// The rightmost number can be 4+ digits long according to
// https://cve.mitre.org/about/faqs.html#cve_id_syntax_change
function linkifyCVE(str) {
  const regexp = /(CVE-\d{4}\-\d{4,})/gi;
  const linkText = "[:vulnerability:$1](/cves/$1)";
  return str.replace(regexp, linkText);
}

// Identify all icon names placed between two colons, like :fi-camera:
// All icon names are replaced with an <i> tag and appropriate class.
// ":mi-bug-report:" turns into "<i class=\"vhp-icons-mi-bug-report\"></i>"
function embedVHPIcons(str) {
  const regex = /:(\w+?):/gi;
  const replaceText = '<i class="vhp-icon-$1"></i>';
  return str.replace(regex, replaceText);
}

function cleanHtml(str) {
  // use sanitize-html library here
  const opts = {
    allowedTags: [
      "p",
      "a",
      "ul",
      "ol",
      "li",
      "b",
      "i",
      "strong",
      "em",
      "strike",
      "abbr",
      "code",
      "hr",
      "pre",
      "h1",
      "h2",
      "h3",
    ],
    disallowedTagsMode: "discard",
    allowedSchemes: ["http", "https"],
    allowedAttributes: {
      a: ["href", "name", "target"],
      i: ["class"],
    },
  };
  return sanitizeHtml(str, opts);
}
