import $ from 'jquery'
import Timeline from './timeline'
import SimpleDate from '../global/simpleDate'
import {vhpMarkdown} from '../global/vhpMarkdown'

function initHTimeline(defaultZoom = 'vcc_to_fix'){
  window.filters = [];

  var settings = {
    margin: { top: 10, bottom: 45, left: 60, right: 60},
    timelineHeight: 16,
    chicHeight: 14,
    chicWidth: 20,
    cLowerLim: 13,
    cUpperLim: 15,
    xAxisHeight: 80,
    fade_ms: 700,
    defaultZoomValue: defaultZoom,
  }

  var h = new Timeline(settings);
  h.init();
  return h;
}

/**
 * sort the events by date oldest to newest
 * @param events - array of hashes with a "date" object
 * @returns {*} - array of sorted event hashes
 */
function sortEvents(events) {
  events.sort(function(a, b) {
      return b.date.getTime() - a.date.getTime();
  });
  return events;
}

function populateHTimeline(hTimeline, vulnEvents) {
  if(vulnEvents.length == 0) { return; } // Zoom levels are not set (using null for ajax fails)

  var zoomLevels = new Map();
  const icon_str = '<i class="vhp-icon-zoom-in">/</i>'
  zoomLevels.set('Origin to Fix', 'vcc_to_fix');
  zoomLevels.set('All Events', 'first_to_last');
  zoomLevels.set('All Time', 'all');

  hTimeline.populateTimelines(vulnEvents, zoomLevels);

  $('body').on('change', '.htimeline_toggle', () => hTimeline.filterToggled());

  // The dropdown list isn't guaranteed to exist at this point, so we put the
  // event handler on the body with an additional selector of "#zoom-dropdown"
  // that will be checked only after the event is triggered.
  $('body').on('change', '#zoom-dropdown', function(event) {
    switch (event.currentTarget.value) {
      case 'all':
        hTimeline.zoomChanged(new Date(0), new Date());
        break;
      case 'vcc_to_fix':
        var minDate, maxDate;
        for (var i = 0; i < vulnEvents.length; i++) {
          // In case no Fix event(s) exist, set a default for maxDate to be
          // the last non-release event
          if (vulnEvents[i].event_type != 'release') {
            maxDate = new Date(vulnEvents[i].date);
            break;
          }
        }

        for (var i = vulnEvents.length - 1; i >= 0; i--) {
          // In case no Vcc event(s) exist, set a default for minDate to be
          // the first non-release event
          if (vulnEvents[i].event_type != 'release') {
            minDate = new Date(vulnEvents[i].date);
            break;
          }
        }

        for (var i = 0; i < vulnEvents.length; i++) {
          if (vulnEvents[i].event_type == 'fix') {
            // Get last fix
            maxDate = new Date(vulnEvents[i].date);
            break;
          }
        }
        for (var i = vulnEvents.length - 1; i >= 0; i--) {
          if (vulnEvents[i].event_type == 'vcc') {
            // Get first VCC
            minDate = new Date(vulnEvents[i].date);
            break;
          }
        }

        hTimeline.zoomChanged(minDate, maxDate);
        break;
      case 'first_to_last':
        for (var i = vulnEvents.length - 1; i >= 0; i--) {
          var type = vulnEvents[i].event_type;
          if (type != 'same directory' && type != 'same cwe' && type != 'release') {
            var minDate = new Date(vulnEvents[i].date);
            break;
          }
        }

        for (var i = 0; i < vulnEvents.length; i++) {
          if (vulnEvents[i].event_type != 'release') {
            var maxDate = new Date(vulnEvents[i].date);
            break;
          }
        }

        hTimeline.zoomChanged(minDate, maxDate);
        break;
      default:
        hTimeline.zoomChanged(new Date(0), new Date());
    }
  });

  $('#zoom-dropdown').change();
}

function populateVTimeline(vulnEvents) {
  function pluralize(count, text) {
    return count > 1 ? text + "s" : text;
  }

  for (let e of vulnEvents) {
    var notes = e.notes;
    var accordion = '';
    if (notes) {
      var template = `
        <ul class="accordion vertical menu" data-accordion-menu>
          <li><a href="#">:menu_name:</a>
              <ul class="menu vertical nested">
                :menu_items:
              </ul>
          </li>
        </ul>`

      if (notes.hasOwnProperty('commits')) {
        var commits = notes.commits;
        var temp_acc = template.replace(':menu_name:', pluralize(Object.keys(commits).length, "Commit"));
        commit_rows = []
        Object.keys(commits).map(function(h) {
          var short_hash = h.substring(0, 12);
          if (commits[h] == "") {
            commit_rows.push(`<li><a class="disabled">${short_hash} (Untracked)</a></li>`);
          } else {
            commit_rows.push(`<li><a href='/commits/${h}'>${short_hash} - ${commits[h]}</a></li>`);
          }
        });
        temp_acc = temp_acc.replace(':menu_items:', commit_rows.join(''));
        accordion += temp_acc;
      }

      if (notes.hasOwnProperty('files')) {
        var files = notes.files;
        var temp_acc = template.replace(':menu_name:',
                    pluralize(Object.keys(files).length, "Impacted File"));
        // Some files aren't found in the DB. In that case, disable the link.
        files = files.map(f => `<li><a ${f.slug == "" ? 'class="disabled"' :
                        `href="/filepaths/${f.slug}"`}>${f.file}</a></li>`);
        temp_acc = temp_acc.replace(':menu_items:', files.join(''));
        accordion += temp_acc;
      }

      if (notes.hasOwnProperty('developers')) {
        var info = '<i class="vhp-icon-info"></i><span>To protect their ' +
                    'privacy, developers are given animal-related nicknames.</span>';
        var devs = notes.developers;
        var temp_acc = template.replace(':menu_name:', pluralize(devs.length, "Developer") + info);
        devs = devs.map(d => d == null ? `<li><a class="disabled">Unidentified Developer</a></li>` :
                `<li><a href='/developers/${d}'>${d}</a></li>`);
        temp_acc = temp_acc.replace(':menu_items:', devs.join(''));
        accordion += temp_acc;
      }
    }

    const block = $('#vtimeline-template').clone();
    block.attr('data-id', e.id);
    block.attr('data-type', e.event_type);
    block.find('.vtimeline-anchor').attr('id',"event_" + e.id);
    if (e.event_type == 'release') {
      block.attr('id', 'release_block_' + e.id);
      block.find('.vtimeline-anchor').attr('id', "release_" + e.id);
    } else {
      block.attr('id', 'event_block_' + e.id);
      block.find('.vtimeline-anchor').attr('id', "event_" + e.id);
    }
    block.find('.title').html(e.title);
    block.find('.description').html(vhpMarkdown(e.description) + accordion);
    block.find('.description').addClass('shortened'); // applies fading
    block.find('.isodate').html(e.date);
    const event_date = new SimpleDate(e.date).timestampFormat();
    block.find('.pretty_date').html(event_date);
    if (e.event_type === "same directory") {
      e.icon = 'compare_arrows' // Sometimes getting incorrect "bug_report" value
      e.color = '#ffaa00' // Value currently found in this event type's "icon" property
    }
    if (e.event_type === "owner change") {
      e.color = '#E660B3' // Accessible color
    }
    block.find('.timeline-icon').html(e.icon);
    block.find('.vtimeline-img').css('background-color', e.color);
    $('#vtimeline').append(block);
    block.show();

    // Hide "See More" and "See Less" elements if the description is small
    // enough or if there is a trivial amount of overflow. Note that at this
    // point in the code each description has already been shortened, so the
    // scrollHeight and clientHeight properties below will reflect that.
    const desc = block.find('.description');
    if (desc.prop('scrollHeight') - desc.prop('clientHeight') < 75) {
      desc.removeClass('shortened');
      desc.css('height', 'initial');
      block.find('.see_more').remove();
      block.find('.see_less').remove();
    }
  }
  $('#vtimeline-template').remove(); // remove so we start upper-left

  // Expand event description when clicked
  $('.see_more').click(function() {
    $(this).attr('hidden', 'true');
    $(this).parent().find('.description').css('height', 'initial');
    $(this).parent().find('.description').toggleClass('shortened');
    $(this).parent().find('.see_less').removeAttr('hidden');
  });

  // Shorten event description when clicked
  $('.see_less').click(function() {
    $(this).attr('hidden', 'true');
    $(this).parent().find('.description').css('height', '');
    $(this).parent().find('.description').toggleClass('shortened');
    $(this).parent().find('.see_more').removeAttr('hidden');
  });

  // Create all of the accordion menus for the numerous links on the page
  // e.g. commits and files
  $('.accordion').each(function(i) {
    var e = new Foundation.AccordionMenu($(this));
  });
}

function load_succeded(vulnEvents, releases) {

  for (let e of vulnEvents[0]) {
    // Here we convert Rails AR date string to JS date object
    // different browsers treat time parsing differently
    // so we need to specify the date format string.

    e.date = new SimpleDate(e.date).createJSDate();
    window.events.push(e); // add to global events
  }

  // FIXME Disabled because this was breaking other code
  // for (let e of releases[0]) {
  //   e.event_type = e.notes.event_type;
  //   e.title = e.notes.title;
  //   e.description = e.notes.description;
  //   e.style_color = e.notes.color;
  //   e.style_icon = e.notes.icon;
  //   e.notes = {}
  //   e.date = new Date(moment(e.date_released, 'YYYY-MM-DD'));
  //   events.push(e); // add to global events
  // }

  let sortedEvents = sortEvents(window.events);
  $('#vtimeline_loading').remove();
  populateVTimeline(sortedEvents);

  $('#htimeline-loading').hide();
  populateHTimeline(window.hTimeline, sortedEvents);
}

function load_failed(vulnEvents, releases) {
  $('#htimeline-loading').hide();
  $('#vtimeline_loading').hide();
  $('#hTimeline').hide();
  $('#htimeline-loading-failed').show();
  $('#vtimeline-loading-failed').show();
}

export default function onVulnerabilitiesShow(){
  window.hTimeline = initHTimeline();
  window.events = []; // global to the page for resizing
  $.when(
    $.ajax(`/api/vulnerabilities/${cve}/events`),
    $.ajax(`/api/projects/${project_slug}/releases`)
  ).then(load_succeded, load_failed);

  window.onresize = function() {
    // Maintain zoom level when resizing
    var zoomValue = $('#zoom-dropdown').val();

    if(zoomValue === null) { // populateHTimeline found no events
      return;
    }

    $('#htimeline-loading').show();
    hTimeline = initHTimeline(zoomValue);
    $('#htimeline-loading').hide();
    populateHTimeline(hTimeline, window.events);
  }

  window.addEventListener("load", ()=>{
    let vulnerabilityTabs = $("#vulnerability-tabs")[0];
    let vulnerabilityTabsClone = $(vulnerabilityTabs).clone();
    let tabContent = $(".tab-content")[0];
    vulnerabilityTabsClone.insertAfter(vulnerabilityTabs);
    vulnerabilityTabsClone = vulnerabilityTabsClone[0];
    vulnerabilityTabsClone.setAttribute("id","vulnerability-tabs-select");
    const vulnerabilityTabsTitles = vulnerabilityTabsClone.querySelectorAll(".tabs-title");
    transformTag(vulnerabilityTabsClone, "select")
    vulnerabilityTabsTitles.forEach((tabTitle)=>{
      transformTag(tabTitle,"option")
    })
    let vulnerabilitySelect = $("select#vulnerability-tabs-select")[0];
    vulnerabilitySelect.addEventListener("change",()=>{
      const tabValue = vulnerabilitySelect.value;
      const currentTab = tabContent.querySelector(".is-active");
      const tabToChange = tabContent.querySelector(tabValue);
      currentTab.classList.remove("is-active");
      tabToChange.classList.add("is-active");
    });
    
    function mediaWidthWatch(x) {
      if (x.matches) {
        if (!document.querySelector(".select-mode")) {
          tabContent.classList.add("select-mode");
        }
        vulnerabilitySelect.style.display = "flex";
        vulnerabilityTabs.style.display = "none";
      }
      else {
        if (document.querySelector(".select-mode")) {
          tabContent.classList.remove("select-mode");
        }
        vulnerabilityTabs.style.display = "flex";
        vulnerabilitySelect.style.display = "none";
      }
    }
    
    function transformTag(tagIdOrElem, tagType){
      var elem = (tagIdOrElem instanceof HTMLElement) ? tagIdOrElem : document.getElementById(tagIdOrElem);
      if(!elem || !(elem instanceof HTMLElement))return;
      var children = elem.childNodes;
      var parent = elem.parentNode;
      var newNode = document.createElement(tagType||"span");
      for(var a=0;a<elem.attributes.length;a++){
          newNode.setAttribute(elem.attributes[a].nodeName, elem.attributes[a].value);
      }
      for(var i= 0,clen=children.length;i<clen;i++){
          if (children[0].tagName === "A") {
            let hrefVal = children[0].getAttribute("href");
            newNode.setAttribute("value",hrefVal)
            children[0].setAttribute("id",children[0].getAttribute("id") + "-2")
          }
          newNode.appendChild(children[0]);
      }
      newNode.style.cssText = elem.style.cssText;
      parent.replaceChild(newNode,elem);
    }
    
    const maxMediaWidth = window.matchMedia("(max-width: 1400px)");
    mediaWidthWatch(maxMediaWidth);
    maxMediaWidth.addListener(mediaWidthWatch)
  })

  const vtimelineSortSelect = document.querySelector(".vtimeline-sort-select")
  vtimelineSortSelect.addEventListener("change",(e)=>{
    if (e.target.value === "1") {
      vtimeline.style.flexDirection = "column"
    }
    else if (e.target.value === "2") {
      vtimeline.style.flexDirection = "column-reverse"
    }
  })
}