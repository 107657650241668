import $ from "jquery";
import Toastify from "toastify-js";
import "toastify-js/src/toastify";
const { CweManager } = require("cwe-sdk");
import "select2/dist/js/select2";
import { vhpMarkdown } from "../global/vhpMarkdown";
import Inputmask from "inputmask";
import moment from "moment/moment";
import YAML from 'yaml'

const parentFields = [];
const childFields = [];
let globalErrors = [];
let currentCVE = null;
const accordionElementId = "questionAccordion";
const projectObj =
  JSON.parse(localStorage.getItem("allProjects")) ??
  fetch("/api/projects")
    .then((response) => response.json())
    .then((data) => {
      localStorage.setItem("allProjects", JSON.stringify(data));
    });

//Define Curation Object
function Curation(cve, project, answers) {
  this.cve = cve ?? getCurrentCuration();
  this.project = project ?? getCurrentProject();
  this.answers = answers ?? [];
}
function scrollIntoViewIfNeeded(target) {
  if (target.getBoundingClientRect().bottom > window.innerHeight) {
    target.scrollIntoView(false);
  }

  if (target.getBoundingClientRect().top < 0) {
    target.scrollIntoView();
  }
}

function resetProgress() {
  if (confirm("Do you wish to clear your progress?") == true) {
    clearProgress();
    window.location.reload();
  }
}
function clearProgress() {
  localStorage.removeItem("VHPWizardStatus");
  console.log("Status Cleared");
}
function getProgress() {
  let wizardStatus = localStorage.getItem("VHPWizardStatus");
  let wizardStatusArr = new Array();
  try {
    wizardStatusArr = JSON.parse(wizardStatus);
    if (wizardStatusArr != null) {
      return wizardStatusArr;
    } else return [];
  } catch (e) {
    alert(e); // error in the above string (in this case, yes)!
  }
}
function getCurationFromDatabase(cve) {
  $.ajax({
    url: `/api/vulnerabilities/${cve}`,
    dataType: "json",
  }).then((data) => {
    console.log(data);
  });
}

function getSavedCurationNotesFromDataBase(cve) {
  $.ajax({
    url: `/api/vulnerabilities/${cve}`,
    dataType: "json",
  }).then((data) => {
    let project = getProjectSubdomain(data.project_id);

    let thisCVE = getCurationInProgress(cve);
    let currentProgress = getProgress();

    const objIndex = currentProgress.findIndex(
      (curation) => curation.cve === cve
    );
    thisCVE.answers = data.notes;
    thisCVE.project = project;
    currentProgress[objIndex] = thisCVE;
    let newProgress = currentProgress;
    localStorage.setItem("VHPWizardStatus", JSON.stringify(newProgress));
    populateYMLField(data.notes);
  });
}
function isCVEFormat(string) {
  const regex = new RegExp(/^CVE-\d{4}-\d{4,}$/);
  let isCVEFormat = regex.test(string) ? true : false;
  return isCVEFormat;
}
function isValueValid(type, fieldName, fieldValue) {
  if (type === "content") {
    return false;
  }
  console.log(`attempt to validate ${fieldName} ${fieldValue} as ${type}`);

  const fieldLabel = getLabelFromName(fieldName);

  let errorMessage;
  let localErrors = [];
  switch (type) {
    case "input":
    case "textarea":
    case "string":
      if (typeof fieldValue !== "string") {
        errorMessage = `value for ${fieldLabel} must be a string`;
        localErrors.push(errorMessage);
        globalErrors.push(errorMessage);
        //return false;
      }
      break;
    case "date":
      var timestamp = Date.parse(fieldValue);
      if (isNaN(timestamp)) {
        errorMessage = `value for ${fieldLabel} must be a date`;
        localErrors.push(errorMessage);
        globalErrors.push(errorMessage);
      }
      break;
    case "boolean":
      if (typeof fieldValue !== "boolean") {
        errorMessage = `value for ${fieldLabel} must be a bool`;
        localErrors.push(errorMessage);
        globalErrors.push(errorMessage);
        //return false;
      }
      break;
    case "number":
      if (isNaN(fieldValue)) {
        errorMessage = `value for ${fieldLabel} must be a number`;
        localErrors.push(errorMessage);
        globalErrors.push(errorMessage);
        //return false;
      }
      break;
    case "url":
      try {
        let urlToCheck = new URL(fieldValue);
      } catch (e) {
        if (e instanceof TypeError) {
          errorMessage = `value for ${fieldLabel} must be a valid URL`;
          localErrors.push(errorMessage);
          globalErrors.push(errorMessage);
          //return false;
        } else {
          throw e;
        }
      }
      break;
    case "cve":
      // Don't create entry if not valid format
      if (!isCVEFormat(fieldValue)) {
        errorMessage = `value for ${fieldLabel} must be a valid CVE`;
        localErrors.push(errorMessage);
        globalErrors.push(errorMessage);
      }
      break;
    default:
      return true;
  }
  let currentField = $(`.accordion-item[data-key="${fieldName}"]`);
  currentField.removeClass("validated valid invalid");
  if (localErrors.length > 0) {
    currentField.addClass("validated invalid");
    return false;
  } else {
    currentField.addClass("validated valid");
    return true;
  }
}
function getCurrentCuration() {
  let currentCuration = localStorage.getItem("CurrentCuration") ?? "No Number";
  return currentCuration;
}
function getCurrentProject() {
  return document.querySelector("body").dataset.project;
}
function setProject(cve) {
  //get Cur
}
function getProjectSubdomain(project_id) {
  return projectObj.filter((p) => p.id === project_id)[0].subdomain;
}
function getProjectQuestions(project, parentsOnly = false) {
  const projectInQuestion = project ?? getCurrentProject();
  if (!localStorage.getItem(`questions-${projectInQuestion}`)) {
    try {
      var questionsToLoad = fetch(
        `/api/curate?project=${projectInQuestion}`
      )
        .then((response) => {
          console.log(
            `Received response from question load: ${response.status}`
          );
          response.json();
        })
        .then((data) => {
          localStorage.setItem(`questions-${project}`, JSON.stringify(data));
        });
    } catch (error) {
      console.log(error);
    }
  } else {
    const projectQuestions = JSON.parse(
      localStorage.getItem(`questions-${projectInQuestion}`)
    );
    let questionsToReturn = projectQuestions;
    if (parentsOnly) {
      questionsToReturn = [];
      projectQuestions.forEach((q) => {
        if (!q.parentField) {
          questionsToReturn.push(q);
        }
      });
    }
    return questionsToReturn;
  }
}
function getLabelFromName(fieldName) {
  const projectQuestions = getProjectQuestions();
  let currentQuestion = projectQuestions.filter((q) => q.key === fieldName)[0];
  return currentQuestion.label;
}
function getConfigFromName(fieldName) {
  const projectQuestions = getProjectQuestions();
  let currentQuestion = projectQuestions.filter((q) => q.key === fieldName)[0];
  return currentQuestion;
}
function getParentQuestionEl(fieldName) {
  let inputField = document.querySelector(`[data-fieldname="${fieldName}"]`);
  //console.log(fieldName, inputField);
  if (inputField != null) {
    let questionElement = inputField.closest(".question");
    return questionElement;
  }
}
function checkForSavedAnswer(fieldName) {
  const currentCuration = getCurrentCuration();
  const currentProgress = getCurationInProgress(currentCuration);
  const projectQuestions = getProjectQuestions();

  let currentQuestion = projectQuestions.filter(function (q) {
    return q.key === fieldName;
  });
  //console.log(currentQuestion, currentProgress.answers);
  let fieldValue = "";
  if (currentQuestion.length == 0) {
    console.log(`Question ${fieldName} does not exist in project`);
    return false;
  }
  if (currentQuestion[0].type == "repeater") {
    fieldValue = [];
    fieldValue.push("");
  }
  if (currentQuestion[0].key == fieldName) {
    Object.entries(currentProgress.answers).filter(function (savedQuestion) {
      if (savedQuestion[0] === fieldName) {
        fieldValue = savedQuestion[1];
        if (fieldValue) {
          //console.log(fieldName, fieldValue, typeof fieldValue);
          if (typeof fieldValue === "string") {
            fieldValue = String(fieldValue).trim();
          } else if (typeof fieldValue === "object" && "answer" in fieldValue) {
            fieldValue = fieldValue.answer ?? "";
          } else if (
            typeof fieldValue === "object" &&
            "commits" in fieldValue
          ) {
            fieldValue = fieldValue.commits ?? [];
          } else if (typeof fieldValue === "object") {
            fieldValue = fieldValue;
          } else {
            fieldValue = JSON.stringify(fieldValue).trim();
          }
        } else {
          fieldValue = String(fieldValue).trim();
        }
        //console.log(`Saved value for ${fieldName} is ${JSON.stringify(fieldValue)}`, typeof fieldValue);
      }
    });
  }
  return fieldValue;
}


function populateYMLField(progress) {
  const yamlField = document.getElementById("YAMLOutput");
  yamlField.value = "";
  // TODO For now, remove all "_instructions" keys from the yml right here. Eventually we need a cleaner way of handling that.
  progress = Object.fromEntries(Object.entries(progress).filter(([k,_v]) => !k.includes('_instructions' )))
  yamlField.value = YAML.stringify(progress, { nullStr: '' })

  // FIXME Old YML emitter wasn't clean enough - BUT, I'm not sure what hte first half of the if-statement is doing here so I'm going to ask.
  // if (progress && Object.keys(progress).some((key) => key === "fieldName")) {
    // progress.forEach(function (savedQuestion) {
    //   yamlField.value +=
    //     savedQuestion.fieldName +
    //     ": " +
    //     JSON.stringify(savedQuestion.fieldVal) +
    //     "\r\n";
    // });
  // } else {
    // Object.entries(progress).forEach(([key, value]) => {
    //   yamlField.value += `${key}: ${JSON.stringify(value)}\r\n`;
    // });
  // }
}
function openYml() {
  $("#open-yaml").trigger("click");
}
function toggleAccordion(direction, element) {
  $(`#${accordionElementId}`).foundation(direction, element);
}
function doToast(text, clickFunction) {
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    stopOnFocus: true,
    style: {
      background: "linear-gradient(to right, #00b09b, #96c93d)",
      borderRadius: "25px",
    },
    onClick: clickFunction,
  }).showToast();
}
function getCurationInProgress(cve) {
  let currentProgress = getProgress();
  let curationInProgress = currentProgress.filter(
    (curation) => curation.cve === cve
  )[0];
  return curationInProgress || new Curation(cve, null, []);
}
function saveProgress(fieldName, fieldVal) {
  const cve = getCurrentCuration();
  let currentProgress = getProgress();
  let questionDataToSave = {
    fieldName: fieldName,
    fieldVal: fieldVal,
  };

  let thisCVE = getCurationInProgress(cve);

  const objIndex = currentProgress.findIndex(
    (curation) => curation.cve === cve
  );

  let currentCVEanswerObj = thisCVE?.answers ?? {};

  //if curation has saved answers, loop through to check against saved data
  console.log(currentProgress, currentCVEanswerObj);
  if (currentCVEanswerObj) {
    console.log(currentCVEanswerObj);
    Object.entries(currentCVEanswerObj).forEach((savedQuestion, index) => {
      console.log(fieldName, savedQuestion[0], index);
      if (fieldName === savedQuestion[0]) {
        currentCVEanswerObj[fieldName] = questionDataToSave.fieldVal;
        questionDataToSave = null;
      }
    });
  }
  if (
    !Object.values(currentCVEanswerObj).includes(fieldName) &&
    questionDataToSave
  ) {
    currentCVEanswerObj[fieldName] = questionDataToSave.fieldVal;
    thisCVE.answers = currentCVEanswerObj;
  }
  currentProgress[objIndex] = thisCVE;
  let newProgress = currentProgress;
  localStorage.setItem("VHPWizardStatus", JSON.stringify(newProgress));

  populateYMLField(currentCVEanswerObj);

  doToast(`${fieldName} added to YAML.`, openYml);
  CheckForChildrenToSave(fieldName);

  if (globalErrors.length == 0) {
    let currentQuestion = $(`.accordion-item[data-key="${fieldName}"]`);
    let nextQuestion = currentQuestion.next();
    toggleAccordion("down", $(nextQuestion).find(".accordion-content"));
    toggleAccordion("up", $(currentQuestion).find(".accordion-content"));
  } else {
    alert(globalErrors.join("\r\n"));
  }
}

//Notes storage
const curationNotesKey = "curation-notes";
const notesInput = document.getElementById("Notes");
if (notesInput) {
  const notesStorage = localStorage.getItem(curationNotesKey);
  if (notesStorage) {
    notesInput.value = notesStorage;
  }
  notesInput.addEventListener("keydown", (e) => {
    localStorage.setItem(curationNotesKey, e.target.value);
  });
}
const clearNotes = document.getElementById("clear-notes");
if (clearNotes) {
  clearNotes.addEventListener("click", () => {
    notesInput.value = "";
    localStorage.removeItem(curationNotesKey);
  });
}
function loadQuestions(project) {
  try {
    var questionsToLoad = fetch(`/api/curate?project=shared`)
      .then((shared) => shared.json())
      .then((sharedData) => {
        fetch(`/api/curate?project=${project}`)
          .then((response) => response.json())
          .then((data) => {
            let mergedQuestions = [...sharedData, ...data];
            localStorage.setItem(
              `questions-${project}`,
              JSON.stringify(mergedQuestions)
            );

            mergedQuestions.forEach((q) => {
              if (!q.parentField) {
                parentFields.push(q);
              } else {
                let childField = {
                  parentName: q.parentField,
                  config: q,
                };
                childFields.push(childField);
              }
            });
            document.querySelector("body").dataset.project = project;

            buildQuestionsAccordion(mergedQuestions);
            getProgress();
          });
      });
  } catch (error) {
    console.log(error);
  }
}

function saveQuestion(q, questionElement) {
  let fieldType = q.type;
  if (fieldType === "content") {
    return;
  }
  //some fields need special validation... set that here.
  let validationType = q.validationType ? q.validationType : q.type;
  let fieldName;
  let fieldVal;

  switch (fieldType) {
    case "input":
    case "textarea":
    case "date":
      let field = questionElement.querySelector(`[data-fieldname="${q.key}"]`);
      fieldName = field.dataset.fieldname;
      fieldVal = field.value;
      break;
    case "select":
    case "cwe-selector":
    case "cve-selector":
      fieldName = questionElement.querySelector("select").dataset.fieldname;
      fieldVal = questionElement.querySelector("select").value;
      if (fieldVal === "") {
        fieldVal = [];
      }
      break;
    case "json":
      fieldName = questionElement.querySelector(`[data-fieldname="${q.key}"]`)
        .dataset.fieldname;
      fieldVal = parseField.json(fieldName);
      break;

    case "checkbox":
      fieldName = questionElement.querySelector(`[data-fieldname="${q.key}"]`)
        .dataset.fieldname;
      fieldVal = parseField.checkbox(fieldName);
      break;
    case "radio":
      fieldName = $(this).find("input[type=radio]").dataset.fieldname;
      fieldVal = parseField.radio(fieldName);
      break;
    case "repeater":
      fieldName = questionElement.querySelector(
        "[data-fieldtype='repeater'] [data-fieldname]"
      ).dataset.fieldname;
      fieldVal = parseField.repeater(fieldName);
      break;
  }
  if (
    q.required === true &&
    (typeof fieldVal === "undefined" || fieldVal == "")
  ) {
    questionElement.classList.remove("validated", "valid", "invalid");
    questionElement.classList.add("validated", "invalid");
    alert("Field Is Required");
    return;
  }

  //Repeaters are validated separately. don't pass them in here, just save already validated values.
  if (fieldType == "repeater") {
    questionElement.classList.remove("validated", "valid", "invalid");
    questionElement.classList.add("validated", "valid");
    saveProgress(fieldName, fieldVal);
  } else {
    if (
      isValueValid(validationType, fieldName, fieldVal) &&
      fieldType != "repeater"
    ) {
      saveProgress(fieldName, fieldVal);
    } else {
      alert(globalErrors.join("\r\n"));
    }
  }
}
function getChildFields(fieldName) {
  var objChildFields = childFields;
  var childOfParent = objChildFields.filter(function (childField) {
    return childField.parentName == fieldName;
  });
  return childOfParent;
}
function CheckForChildrenToSave(fieldName) {
  var objChildFields = childFields;
  var childOfParent = objChildFields.filter(function (childField) {
    return childField.parentName === fieldName;
  });
  if (childOfParent.length > 0) {
    for (const key in childOfParent) {
      if (childOfParent.hasOwnProperty(key)) {
        let childConfig = childOfParent[key].config;
        let questionElement = getParentQuestionEl(fieldName);
        questionElement = questionElement.querySelector(
          `.sub-field[data-fieldname="${childConfig.key}"]`
        );
        saveQuestion(childConfig, questionElement);
      }
    }
  } else {
    //console.log("Children Not Found");
    //return false;
  }
}
function CheckForChildrenToBuild(q) {
  //console.log(`Checking For Children of ${fieldName}...`);
  if (q.type === "repeater") {
    return "";
  }
  const fieldName = q.key.toString();
  let childOfParent = getChildFields(fieldName);
  let childFieldHTML = "";
  if (childOfParent.length > 0) {
    if (childOfParent[0].parentName === fieldName) {
      childFieldHTML += `<div class="sub-fields" data-parent="${fieldName}">`;
      for (const key in childOfParent) {
        if (childOfParent.hasOwnProperty(key)) {
          let childConfig = childOfParent[key].config;

          let fieldValue = checkForSavedAnswer(childConfig.key);
          //console.log("Child Field Config", childConfig);
          childFieldHTML += `<div class="sub-field option-field" data-accepted-values="${
            childConfig.acceptedValues
          }" data-operator="${childConfig.comparisonType.toUpperCase()}" data-fieldtype="${
            childConfig.type
          }" data-fieldname="${childConfig.key}">${buildQuestionFromConfig(
            childConfig,
            "",
            fieldValue
          )}
            </div>`;
        }
      }
      childFieldHTML += `</div>`;
    }
    return childFieldHTML;
  } else {
    //console.log("Children Not Found");
    return "";
  }
}
function getRepeaterChildren(q) {
  const fieldName = q.key.toString();
  let childOfParent = getChildFields(fieldName);
  let childFieldHTML = "";
  if (childOfParent.length > 0) {
    if (childOfParent[0].parentName === fieldName) {
      childFieldHTML += `<div class="repeater-fields" data-parent="${fieldName}" data-fieldname="${fieldName}">`;
      for (const key in childOfParent) {
        if (childOfParent.hasOwnProperty(key)) {
          let childConfig = childOfParent[key].config;

          let fieldValue = checkForSavedAnswer(childConfig.key);
          //console.log("Child Field Config", childConfig);
          childFieldHTML += `<div class="sub option-field" data-accepted-values="${
            childConfig.acceptedValues
          }" data-operator="${childConfig.comparisonType.toUpperCase()}" data-fieldtype="${
            childConfig.type
          }" data-fieldname="${childConfig.key}">${buildQuestionFromConfig(
            childConfig,
            "",
            fieldValue
          )}
          </div>`;
        }
      }
      childFieldHTML += `</div>`;
    }
    return childFieldHTML;
  } else {
    //console.log("Children Not Found");
    return "";
  }
}
function buildRepeater(
  repeaterConfig,
  questionElement,
  fieldValue,
  maxRows = 99
) {
  let answers = questionElement.querySelectorAll(
    `.repeater-fields[data-parent]`
  );
  const maximumRows = maxRows;
  let repeaterFieldSet = answers[0].dataset.parent;
  let repeatedFields = questionElement.querySelectorAll(".repeater-entry");

  let lastRow = answers[answers.length - 1];
  let lastRepeaterRow = repeatedFields[repeatedFields.length - 1];

  //console.log(answers, repeatedFields, repeaterFieldSet, fieldValue);

  let wrap = document.createElement("div");
  let removeButton = document.createElement("button");
  wrap.classList.add("repeater-entry");
  removeButton.classList.add("button", "remove-entry");
  removeButton.innerText = "X";

  let entryLimit =
    questionElement.querySelectorAll(".addRepeater")[0].dataset.limit;
  let existingEntries = repeatedFields.length + 1;

  //isValueValid(repeaterType, config.key, fieldValue);
  //console.log(existingEntries, entryLimit);
  if (existingEntries < entryLimit && existingEntries < maximumRows) {
    let newRow = lastRow.cloneNode(true);
    //loop through new row and parse out the value to the cloned fields
    //console.log(repeaterConfig, fieldValue, newRow);

    wrap.appendChild(newRow);
    wrap.appendChild(removeButton);
    if (repeatedFields.length > 0) {
      lastRepeaterRow.after(wrap);
    } else {
      lastRow.after(wrap);
    }
    if (typeof fieldValue !== "object") {
      let fieldInQuestion = newRow.querySelector(
        `[data-fieldname="${repeaterConfig.key}"]:not(div)`
      );
      if (fieldInQuestion) {
        fieldInQuestion.value = fieldValue ?? "";
      }
    } else {
      for (const [key, value] of Object.entries(fieldValue)) {
        let fieldInQuestion = newRow.querySelector(
          `[data-fieldname="${repeaterFieldSet}_${key}"]:not(div)`
        );
        //console.log(fieldInQuestion, key, value);
        fieldInQuestion.value = value ?? "";
      }
    }
  }

  removeButton.addEventListener(
    "click",
    function () {
      this.closest(".repeater-entry").remove();
    },
    false
  );
}
function buildQuestionFromConfig(q, fieldHTML = "") {
  const key = q.key.toString();
  const label = q.label.toString();
  let isRequired = q.required === true ? "required " : " ";
  let repeaterEntryLimit = q.entryLimit ? q.entryLimit : 3;
  let value = checkForSavedAnswer(key);
  //console.log(`Building field ${q.key}`, value);

  let tooltipHTML =
    q.tooltipText != null
      ? `<i class="fa fa-question-circle" data-toggle="tooltip" data-position="right" title="${q.tooltipText}"></i>`
      : "";

  switch (q.type) {
    case "content":
      fieldHTML += `<span data-fieldname="${key}"></span>`;
      break;
    case "string":
    case "input":
    case "number":
      fieldHTML += `<label for ="${label}">${label} ${tooltipHTML}</label>
      <input data-fieldName="${key}" ${isRequired} type="text" value="${value}" />`;
      break;
    case "repeater":
      fieldHTML += getRepeaterChildren(q);
      fieldHTML += `<div class="clearfix addRepeaterWrapper"><button class="button float-right addRepeater" data-limit="${repeaterEntryLimit}">+ Add Entry</button></div>`;
      break;
    case "date":
      fieldHTML += `<label for ="${label}">${label} ${tooltipHTML}</label><input data-fieldName="${key}" ${isRequired} type="date" value="${value}" />`;
      break;

    case "json":
      fieldHTML += `<label>${label} (JSON) ${tooltipHTML}</label>
      <textarea data-fieldname="${key}">${JSON.stringify(value)}</textarea>`;
      break;

    case "textarea":
      fieldHTML += `<label>${label} ${tooltipHTML}</label>
      <textarea rows="8" data-fieldname="${key}" ${isRequired}>${value.replace(
        /\n/g,
        " "
      )}</textarea>`;
      break;

    case "select":
      fieldHTML += `<label>${label} ${tooltipHTML}</label>
      <select data-fieldname="${key}" ${
        q.SelectMultiple === true ? "multiple" : ""
      } ${isRequired} ${
        q.AllowClear === true ? "data-allowclear='true' " : ""
      } ${q.EnableCustomInput === true ? "data-enablecustominput" : ""}>`;

      if (q.SelectMultiple != true) {
        fieldHTML += `<option value="">Select One</option>`;
      }
      $.each(q.Options, function (k, v) {
        //console.log(k,v);
        fieldHTML += `<option value="${this.Value}" ${
          this.Value.toString() === value ? "selected" : ""
        }>${this.Label}</option>`;
      });
      fieldHTML += `</select>`;
      break;

    case "checkbox":
      var groupName = key;
      fieldHTML += `<label>${label} ${tooltipHTML}</label>`;
      q.Options.forEach(function (option, index) {
        let defaultChecked = option.Value.toString() === value ? "checked" : "";
        fieldHTML += `<div class="form-check"><input type="checkbox" value="${option.Value}" aria-describedby="checkbox-single-desc" data-fieldname="${groupName}" ${defaultChecked}></input><label for="checkbox-single">${option.Label}</label>`;
        fieldHTML += `<span id="checkbox-single-desc" class="visually-hidden">${option.Label}<span>`;
        fieldHTML += "</div>";
      });
      break;

    case "radio":
      var groupName = key;
      fieldHTML += `<label>${label} ${tooltipHTML}</label>`;
      $.each(q.Options, function (k, v) {
        fieldHTML += `<div class="form-check">`;
        fieldHTML += `<label class="form-check-label"><input class="form-check-input" data-fieldname="${groupName}" name="${this.groupName}" type="radio" value="${this.Value}">${this.Name}</label>`;
        fieldHTML += `</div>`;
      });
      break;
    case "cve-selector":
      fieldHTML += `<label>${label} ${tooltipHTML}</label>
      <select data-fieldname="${key}" ${isRequired} id="${key}-id" aria-describedby="${key}-id-label"></select>`;
      break;
    case "cwe-selector":
      fieldHTML += `<label>${label} ${tooltipHTML}</label>
      <select multiple="multiple" data-fieldname="${key}" ${isRequired} id="${key}-id" aria-describedby="${key}-id-label"></select>`;
      break;
    default:
      fieldHTML += `<label>Unknown field<small>(Unknown Field Type)</small></label>
      <input data-fieldname="${key}" type="text" value="${JSON.stringify(
        q
      )}" />`;
      break;
  }
  if (q.instructions) {
    fieldHTML += `
      <span class="description vhp-markdown">
        ${vhpMarkdown(q.instructions)}
      </span>
    `;
  }
  if (q.howToFind) {
    fieldHTML += `
      <h6 class="curation-subheader">How to Find</h6>
      <span class="description vhp-markdown">
        ${vhpMarkdown(q.howToFind)}
      </span>
    `;
  }
  if (q.examples) {
    fieldHTML += `<h6 class="curation-subheader">Example${
      Array.isArray(q.examples) ? "s" : ""
    }</h6>`;
    if (Array.isArray(q.examples)) {
      fieldHTML += `<ul>`;
      q.examples.forEach((example) => {
        fieldHTML += `<li class="example vhp-markdown">${example}</li>`;
      });
      fieldHTML += `</ul>`;
    } else {
      fieldHTML += `
        <span class="example vhp-markdown">
          ${vhpMarkdown(q.examples)}
        </span>
      `;
    }
  }
  fieldHTML += CheckForChildrenToBuild(q);
  //console.log(fieldHTML);
  return fieldHTML;
}
function buildQuestionsAccordion(questions) {
  const project = getCurrentProject();
  let projectCard = document.querySelector(`.card[data-project="${project}"]`);
  let projectImg = projectCard.getElementsByTagName("img")[0].outerHTML;
  let projectName = projectCard.querySelector("h4").innerText;
  let projectTextBg = projectCard.style.backgroundColor;

  let html = `<div class='project-header'>
            ${projectImg}
            <h3 style ='background:${projectTextBg}'>${projectName}</h3>
            </div>`;
  currentCVE = getCurrentCuration();
  if (currentCVE) {
    let curationInProgress = getProgress();
    if (curationInProgress.length > 0) {
      html += `<select class="cve-toggle">`;
      curationInProgress.forEach((curation, index) => {
        html += `<option value='${index}'> ${index == 0 ? `Now Curating: ` : ""}
          ${curation.cve}</option>`;
      });

      html += `</select>`;
    }

    html += populateImportantLinks(currentCVE, project)
  }
  html += `<ul class="accordion" id="${accordionElementId}" data-accordion data-allow-all-closed="true" data-multi-expand="false">`;
  parentFields.forEach((q, index) => {
    let isLast = index >= parentFields.length - 1;

    html += `<li class="accordion-item question" data-accordion-item data-key="${
      q.key
    }" data-fieldtype="${q.type}">
              <a href="#" class="accordion-title">${q.label} ${
      q.summary ? "<span>" + q.summary + "</span>" : ""
    }
      </a> <div class="accordion-content" data-tab-content><div class="question-content">${buildQuestionFromConfig(
        q,
        ""
      )}
      </div><div class="button-group align-right question-actions">
        ${
          !q.required
            ? '<button id="skipButton" class="button primary hollow skipButton">Skip</button>'
            : ""
        }
        ${
          index != 0
            ? '<button id="prevButton" class="button primary prevButton">Previous</button>'
            : ""
        }
      <button type="button" class="button primary nextButton" id="nextButton">Save &amp; ${
        isLast ? "Show YAML" : "Next"
      }</button>
      </div>`;
  });

  html += "</ul>";
  const element = document.getElementById("curationTool");
  element.setAttribute("class", "");
  element.innerHTML = html;
  parentFields.forEach((q, index) => {
    let validationType = q.validationType ? q.validationType : q.type;

    if (checkForSavedAnswer(q.key) != "" && q.type != "repeater") {
      isValueValid(validationType, q.key, checkForSavedAnswer(q.key));
    }
    let isLast = index >= parentFields.length - 1;
    let questionAccordionElement = document.querySelector(
      `li[data-key="${q.key}"]`
    );

    bindCVESelector(q);
    bindCweSelector(q);
    bindConditionalLogic(q);
    questionAccordionElement.querySelector(".nextButton").addEventListener(
      "click",
      function () {
        let questionElement = this.closest(".question");
        let nextQuestion = questionElement.nextSibling;
        globalErrors = [];
        saveQuestion(q, questionElement);
        if (isLast) {
          $("#open-yaml").trigger("click");
        } else {
          scrollIntoViewIfNeeded(nextQuestion);
        }
      },
      false
    );
    questionAccordionElement.querySelector(".skipButton")?.addEventListener(
      "click",
      function () {
        let nextQuestion = questionAccordionElement.nextElementSibling;
        if (isLast) {
          $("#open-yaml").trigger("click");
        } else {
          toggleAccordion("down", $(nextQuestion).find(".accordion-content"));
          toggleAccordion("up", $(this).find(".accordion-content"));

          scrollIntoViewIfNeeded(nextQuestion);
        }
      },
      false
    );
    questionAccordionElement.querySelector(".prevButton")?.addEventListener(
      "click",
      function () {
        let previousQuestionElement =
          questionAccordionElement.previousElementSibling;
        toggleAccordion(
          "down",
          $(previousQuestionElement).find(".accordion-content")
        );
        toggleAccordion(
          "up",
          $(questionAccordionElement).find(".accordion-content")
        );
      },
      false
    );
  });

  $(`#${accordionElementId}`).foundation();
  bindRepeaters();
}

function populateImportantLinks(cve, project) {
  const nvdUrl = `https://nvd.nist.gov/vuln/detail/${cve}`
  const githubUrl = `https://github.com/VulnerabilityHistoryProject/vulnerabilities/blob/dev/cves/${project}/${cve}.yml`
  return `
    <a href="${nvdUrl}"    class="important-links" target="blank" id="cveLink">${cve} on nvd.nist.gov</a>
    <a href="${githubUrl}" class="important-links" target="blank" id="ymlGitHubLink">${cve}.yml on our GitHub</a>
  `
}

function setConditionalToggle(event) {
  let fieldValue = event.target.value ? event.target.value : "";
  let subFieldParent = event.target.dataset.fieldname;
  let subFields = document.querySelectorAll(
    `[data-parent='${subFieldParent}']>.sub-field`
  );
  event.stopPropagation();

  subFields.forEach(function (element, index) {
    let subfield = element;
    let operator = subfield.dataset.operator;
    let acceptedValuesList = subfield.dataset.acceptedValues;

    let acceptedValuesObj = [];
    if (acceptedValuesList.includes(",")) {
      acceptedValuesList = acceptedValuesList.split(",");
      acceptedValuesList.forEach(function (k, v) {
        acceptedValuesObj.push(acceptedValuesList[v]);
      });
    } else {
      acceptedValuesObj.push(acceptedValuesList);
    }
    let subfieldGroup = document.querySelector(
      `[data-parent='${subFieldParent}']`
    );
    switch (operator) {
      case "IN":
        if (
          acceptedValuesObj.includes(fieldValue.toString()) &&
          (event.target.type != "checkbox" ||
            (event.target.type == "checkbox" && event.target.checked === true))
        ) {
          subfieldGroup.style.display = "block";
        } else {
          subfieldGroup.style.display = "none";
        }
        break;
      case "NOT IN":
        if (
          !acceptedValuesObj.includes(fieldValue.toString()) &&
          (event.target.type != "checkbox" ||
            (event.target.type == "checkbox" && event.target.checked === false))
        ) {
          subfieldGroup.style.display = "block";
        } else {
          subfieldGroup.style.display = "none";
        }
        break;
    }
  });
}
function bindConditionalLogic(q) {
  if (childFields.length && q.type != "repeater") {
    let parent = q;
    let parentField = document.querySelector(
      `[data-fieldname="${parent.key}"]`
    );
    childFields.forEach(function (element, index) {
      if (childFields[index].parentName == parent.key) {
        setTimeout(function () {
          if (q.type != "content") {
            parentField.dispatchEvent(new Event("input"));
            parentField.dispatchEvent(new Event("change"));
          }
        }, 0);
      }
    });
    let children = getChildFields(parent.key);
    for (const key in children) {
      if (children.hasOwnProperty(key)) {
        let childConfig = children[key].config;
        let childField = document.querySelector(
          `[data-fieldname="${childConfig.key}"]`
        );
        childField.addEventListener("input", setConditionalToggle);
        childField.addEventListener("change", setConditionalToggle);
        setTimeout(function () {
          childField.dispatchEvent(new Event("input"));
          childField.dispatchEvent(new Event("change"));
        }, 0);
      }
    }
    if (q.type != "content") {
      parentField.addEventListener("input", setConditionalToggle);
      parentField.addEventListener("change", setConditionalToggle);
    }
  }
}
function bindRepeaters() {
  const projectQuestions = getProjectQuestions();

  Object.entries(projectQuestions).forEach((question) => {
    const questionData = question[1];
    const qKey = questionData.key;
    const repeaterFieldSet = qKey.split("_repeater")[0];
    let questionElement = getParentQuestionEl(qKey);

    if (questionData.type === "repeater") {
      //console.log(questionElement);
      questionElement.querySelector(".addRepeater").addEventListener(
        "click",
        function () {
          buildRepeater(questionData, questionElement);
        },
        false
      );
    }

    if (questionData.parentField != null) {
      let parentConfig = getConfigFromName(questionData.parentField);
      //console.log(parentConfig, questionData);
      if (parentConfig && parentConfig.type === "repeater") {
        let answerArr = checkForSavedAnswer(
          parentConfig.key.split("_repeater")[0]
        );

        if (Array.isArray(answerArr) && answerArr.length > 0) {
          if (typeof answerArr[0] === "string") {
            let firstField = questionElement.querySelectorAll(`
              [data-fieldname="${qKey}"]:not(div)`)[0];
            firstField.value = answerArr[0];
            answerArr.slice(1, answerArr.length).forEach((answer) => {
              buildRepeater(questionData, questionElement, answer);
            });
          } else {
            //console.log(answerArr, repeaterFieldSet, answerArr.length);
            for (const [key, value] of Object.entries(answerArr[0])) {
              let firstRowField = questionElement.querySelector(
                `[data-fieldname="${
                  parentConfig.key.split("_repeater")[0]
                }_${key}"]:not(div)`
              );
              firstRowField.value = value ?? "";
              answerArr.slice(1, answerArr.length).forEach((answer) => {
                buildRepeater(
                  questionData,
                  questionElement,
                  answer,
                  answerArr.length
                );
              });
            }
          }
        }
      }
    }
  });
}
let parseField = {
  input: function (field) {
    return $(field).find("input").val();
  },
  date: function (field) {
    return $(field).find("input").val();
  },
  textArea: function (field) {
    return $(field).find("input").val();
  },
  number: function (field) {
    let result = $(field).find("input").val();

    if (isNaN(result)) {
      //console.log("String: " + result, typeof result);
      return result;
    } else {
      return parseInt(result.valueOf());
    }

    return result;
  },
  select: function (field) {
    var result = $(field).find("select").val();
    var label = $(field).find("label").text();
    if ($(field).find("select").prop("required")) {
      if (result == null || result == "") {
        globalErrors.push(`${label} cannot be empty`);
      }
    }
    if (result == null) {
      return "";
    }
    if (typeof result == "string") {
      if (isNaN(result)) {
        return result;
      } else {
        return parseInt(result.valueOf());
      }
    } else if (typeof result == "object") {
      var objResult = [];
      $.each(result, function (k, v) {
        if (isNaN(v)) {
          objResult.push(v);
        } else {
          objResult.push(parseInt(v.valueOf()));
        }
      });
      return objResult;
    }
  },
  checkbox: function (fieldName) {
    let checkboxes = document.querySelectorAll(
      `[data-fieldname="${fieldName}"]`
    );
    let valuesToSave = [];
    checkboxes.forEach(function (field) {
      valuesToSave.push(field.value);
    });
    if (valuesToSave.length == 1) {
      valuesToSave = valuesToSave[0];
    }
    return valuesToSave;
  },
  radio: function (field) {
    return $(field).find("input[type=radio]").is(":checked");
  },
  json: function (fieldName) {
    let jsonField = document.querySelector(`[data-fieldname="${fieldName}"]`);
    return JSON.parse(jsonField.value);
  },
  repeater: function (fieldName) {
    let config = getConfigFromName(fieldName);
    let parentEl = getParentQuestionEl(fieldName);
    let repeaterFields = parentEl.querySelectorAll(
      `.repeater-fields [data-fieldname="${fieldName}"]`
    );
    let valuesToSave = [];
    repeaterFields.forEach(function (field) {
      if (isValueValid(config.validationType, fieldName, field.value)) {
        valuesToSave.push(field.value);
      }
    });
    //console.log(valuesToSave);
    return valuesToSave;
  },
};

function bindCVESelector(q) {
  if (q.type !== "cve-selector") {
    return;
  }
  const project = getCurrentProject();
  $.ajax({
    url: "/api/vulnerabilities",
    dataType: "json",
  }).then((data) => {
    let cveNames = data
      .filter((entry) => entry.subdomain == project)
      .map((entry) => entry.cve)
      .sort();
    $(`select[data-fieldname="${q.key}"]`)
      .select2({
        placeholder: {
          id: "-1",
          text: "--- Type or Select a CVE ---",
          selected: "selected",
        },
        width: "100%",
        allowClear: true,
        data: cveNames,
        tags: true,
      })
      .on("select2:select", function (evt) {
        const regex = new RegExp(/^CVE-\d{4}-\d{4,}$/);
        // Don't create entry if not valid format
        if (!regex.test(evt.params.data.text)) {
          alert("Entry is not in valid CVE Format: CVE-0000-0000");
          $(evt.target).val(null).trigger("change");
          return null;
        }
      });
    let value = checkForSavedAnswer(q.key);
    $(`select[data-fieldname="${q.key}"]`).val(value).trigger("change");
  });
}
function bindCweSelector(q) {
  if (q.type !== "cwe-selector") {
    return;
  }
  const cweManager = new CweManager();
  const result = cweManager.isChildOf({ weaknessId: "117", parentId: "116" });

  //Software Development CWE Category Seeding
  const cweDictArray = Object.values(cweManager.cweDictionary);
  const sdc = [
    ["API/Function Errors", "1228"],
    ["Audit/Logging Errors", "1210"],
    ["Authentication Errors", "1211"],
    ["Authorization Errors", "1212"],
    ["Bad Coding Practices", "1006"],
    ["Behavioral Problems", "438"],
    ["Business Logic Errors", "840"],
    ["Communication Channel Errors", "417"],
    ["Complexity Issues", "1226"],
    ["Concurrency Issues", "557"],
    ["Credentials Management Errors", "255"],
    ["Cryptographic Issues", "310"],
    ["Key Management Errors", "320"],
    ["Data Integrity Issues", "1214"],
    ["Data Processing Errors", "19"],
    ["Data Neutralization Issues", "137"],
    ["Documentation Issues", "1225"],
    ["File Handling Issues", "1219"],
    ["Encapsulation Issues", "1227"],
    ["Error Conditions, Return Values, Status Codes", "389"],
    ["Expression Issues", "569"],
    ["Handler Errors", "429"],
    ["Information Management Errors", "199"],
    ["Initialization and Cleanup Errors", "452"],
    ["Data Validation Issues", "1215"],
    ["Lockout Mechanism Errors", "1216"],
    ["Memory Buffer Errors", "1218"],
    ["Numeric Errors", "189"],
    ["Permission Issues", "275"],
    ["Pointer Issues", "465"],
    ["Privilege Issues", "265"],
    ["Random Number Issues", "1213"],
    ["Resource Locking Problems", "411"],
    ["Resource Management Errors", "399"],
    ["Signal Errors", "387"],
    ["State Issues", "371"],
    ["String Errors", "133"],
    ["Type Errors", "136"],
    ["User Interface Security Issues", "355"],
    ["User Session Errors", "1217"],
  ];
  const softwareDevelopmentCategories = sdc.map((s, i) => {
    return {
      id: s[1],
      text: s[0],
      children: [],
    };
  });
  softwareDevelopmentCategories.push({
    id: "2000",
    text: "Uncategorized",
    children: [],
  });

  const softwareDevelopmentViews = softwareDevelopmentCategories.map((cat) => {
    cweDictArray.map((cwe) => {
      const cweId = cwe.attr["@_ID"];
      cweManager.cweMemberships.map((mem) => {
        if (mem.weaknessId == cweId) {
          if (mem.memberships.includes(cat.id)) {
            cat.children.push({
              id: cweId,
              text: `#${cwe.attr["@_ID"]}: ${cwe.attr["@_Name"]}`,
            });
          }
        }
      });
    });
    return cat;
  });

  cweDictArray.map((cwe) => {
    const cweId = cwe.attr["@_ID"];
    const inSdv = softwareDevelopmentViews.map(({ children }) => {
      const inChildren = children.forEach((c) => {
        return c.id === cweId;
      });
      return inChildren ? true : false;
    });
    if (!inSdv.includes(true)) {
      softwareDevelopmentCategories.map((sdc, i) => {
        if (sdc.id === "2000") {
          sdc.children.push({
            id: cweId,
            text: `#${cwe.attr["@_ID"]}: ${cwe.attr["@_Name"]}`,
          });
        }
      });
    }
  });
  let cweSelector = $(`select[data-fieldname="${q.key}"]`);

  cweSelector
    .select2({
      placeholder: {
        id: "-1",
        text: "--- Type or Select a CWE ---",
        selected: "selected",
      },
      width: "100%",
      allowClear: true,
      data: softwareDevelopmentViews,
    })
    .on("select2:select", function (e) {
      let value = cweSelector.val();
      let dupes = value.filter((c, index) => {
        return value.indexOf(c) !== index;
      });
      let uniqueChars = value.filter((c, index) => {
        return value.indexOf(c) === index;
      });

      cweSelector.val(uniqueChars).trigger("change");
      dupes.forEach((dupe) => {
        cweSelector
          .siblings(".select2")
          .first()
          .find(`.select2-selection__choice[title*=${dupe}]`)
          .first()
          .remove();
      });
    });
  let value = checkForSavedAnswer(q.key);
  if (typeof value === "string" && value.includes(",")) {
    value = value.split(",");
  }
  cweSelector.val(value).trigger("change");
}

export default function onCurate() {
  const newCVEfield = document.getElementById("newCVE");
  const newCVEbutton = document.getElementById("startNewCuration");
  const beginCurationButton = document.getElementById("BeginCuration");
  const resumeCurationButton = document.getElementById("ContinueCuration");

  let curationInProgress = getProgress();
  let curationsInProgressCount = curationInProgress?.length
    ? curationInProgress.length
    : 0;

  resumeCurationButton.classList.add("disabled");
  if (curationsInProgressCount === 0) {
    document.querySelectorAll("#splash .cell")[0].classList.add("no-curations");
  } else {
    const inProgressList = document.getElementById("inProgressCurations");

    curationInProgress.forEach((element, index) => {
      const cve = element.cve;
      const project = element.project;
      const answers = element.answers ?? [];
      let item = document.createElement("div");
      let checkbox = document.createElement("input");
      let checkboxId = `cip_${cve}`;
      checkbox.type = "radio";
      checkbox.id = checkboxId;
      checkbox.name = "curationToSelect";
      checkbox.value = cve;
      var label = document.createElement("label");
      label.htmlFor = checkboxId;
      var labelTextWrapper = document.createElement("span");
      labelTextWrapper.innerHTML = cve;
      label.appendChild(labelTextWrapper);
      if (project) {
        let projectBadge = document.createElement("img");
        let projectBadgeWrapper = document.createElement("span");
        projectBadgeWrapper.toggleAttribute("data-tooltip");
        projectBadgeWrapper.title = project;
        projectBadge.classList.add(`${project}-med-logo`);
        projectBadge.title = project;
        projectBadgeWrapper.appendChild(projectBadge);
        label.appendChild(projectBadgeWrapper);
        let projectQuestions = getProjectQuestions(project);
        let projectQuestionCount = Object.keys(projectQuestions).length;
        let questionsAnswered = Object.keys(answers).length ?? 0;
        //console.log(cve, questionsAnswered);
        //todo only count required fields
        let percentageAnswered = Math.floor(
          (questionsAnswered / projectQuestionCount) * 100
        );

        let projectQuestionCirle = `
          <svg width="100%" height="100%" viewBox="0 0 40 40" class="donut">
            <circle class="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="transparent"></circle>
            <circle class="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="5"></circle>
            <circle class="donut-segment donut-segment-2" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="5" stroke-dasharray="${percentageAnswered} ${
          100 - percentageAnswered
        }" stroke-dashoffset="25"></circle>
          </svg>
        `;
        var projectQuestionCirleEl = document.createElement("div");
        projectQuestionCirleEl.classList.add("question-progress");
        projectQuestionCirleEl.innerHTML = projectQuestionCirle;
        label.appendChild(projectQuestionCirleEl);
      }
      item.classList.add("card-section");
      item.dataset.project = project;
      item.appendChild(checkbox);
      item.appendChild(label);
      inProgressList.append(item);
    });
    const inProgressCurations = document.querySelectorAll(
      "#inProgressCurations>.card-section"
    );
    for (let i = 0; i < inProgressCurations.length; i++) {
      inProgressCurations[i].addEventListener("click", function () {
        let parentEls = inProgressCurations;
        var c = 0;
        while (c < parentEls.length) {
          parentEls[c++].classList.remove("selected");
        }
        parentEls[i].classList.add("selected");
        resumeCurationButton.classList.remove("disabled");
      });
      inProgressCurations[i].addEventListener("mouseover", function () {
        document.getElementById("curationSummary").innerHTML = this.innerText;
      });
    }
    resumeCurationButton.addEventListener("click", function () {
      if (!resumeCurationButton.classList.contains("disabled")) {
        let curationToLoad = inProgressList.querySelector(
          ".card-section.selected"
        );
        let curationCVE = curationToLoad.querySelector("input").value;
        let curationProject = curationToLoad.dataset.project;
        localStorage.setItem("CurrentCuration", curationCVE);
        $("#splash").addClass("hide");
        $("#curationTool").removeClass("hide");
        loadQuestions(curationProject);
        const currentCuration = getCurationInProgress(curationCVE);
        populateYMLField(currentCuration.answers);
      }
    });
  }
  beginCurationButton.addEventListener("click", function () {
    const cveSelected = $("#cve-lookup").find(":selected").val();

    localStorage.setItem("CurrentCuration", cveSelected);

    $.ajax({
      url: `/api/vulnerabilities/${cveSelected}`,
      dataType: "json",
    }).then((data) => {
      //FIXME fixing upvotes in a janky way because I'm on a deadline
      if(data.notes.upvotes === null) {
        data.notes.upvotes = 0
      }

      let project = getProjectSubdomain(data.project_id);
      let thisCVE =
        getCurationInProgress(cveSelected) ??
        new Curation(cveSelected, data.notes, project);
      let currentProgress = getProgress();
      const objIndex = currentProgress.findIndex(
        (curation) => curation.cve === cveSelected
      );
      thisCVE.answers = data.notes;
      thisCVE.project = project;
      if (project) {
        $(`#curationTool .card[data-project="${project}"]`).trigger("click");
      }
      if (objIndex >= 0) {
        currentProgress[objIndex] = thisCVE;
      } else {
        currentProgress.push(thisCVE);
      }

      let newProgress = currentProgress;

      localStorage.setItem("VHPWizardStatus", JSON.stringify(newProgress));
      populateYMLField(data.notes);
      loadQuestions(project);
      $("#splash").addClass("hide");
      $("#curationTool").removeClass("hide");
    });
  });
  function isCVEFieldValid() {
    if (newCVEfield.inputmask.isComplete()) {
      newCVEbutton.disabled = false;
    } else {
      newCVEbutton.disabled = true;
    }
  }
  Inputmask({
    mask: "CVE-9{4}-9{4,}",
    onKeyValidation: function (key, result) {
      isCVEFieldValid();
    },
  }).mask(newCVEfield);
  newCVEfield.addEventListener(
    "keyup",
    function () {
      isCVEFieldValid();
    },
    false
  );
  newCVEbutton.addEventListener(
    "click",
    function () {
      const newCVEvalue = newCVEfield.value;
      const cveList = document.getElementById("cve-lookup");
      let progress = getProgress();
      let curationsInProgress = progress?.length ? progress.length : 0;
      let answers = [];
      if (curationsInProgress === 0) {
        progress = new Array();
      }
      for (var i = 0, n = cveList.options.length; i < n; i++) {
        if (cveList.options[i].value === newCVEvalue) {
          alert("CVE Exists in list... loading from database");
          const currentCuration = getCurationInProgress(newCVEvalue);
          loadQuestions(currentCuration.project);
          populateYMLField(currentCuration.answers);
        }
      }
      if (
        progress.filter((curation) => {
          console.log(curation);
          curation.cve === newCVEvalue;
        }).length > 0
      ) {
        progress[curationsInProgress] = new Curation(
          newCVEvalue,
          null,
          answers
        );
      }
      localStorage.setItem("CurrentCuration", newCVEvalue);
      localStorage.setItem("VHPWizardStatus", JSON.stringify(progress));
      $("#splash").addClass("hide");
      $("#curationTool").removeClass("hide");
      return true;
    },
    false
  );

  /* QUESTION PATH*/
  const projectCards = document.querySelectorAll("#curationTool .card");

  for (let i = 0; i < projectCards.length; i++) {
    projectCards[i].addEventListener(
      "click",
      function () {
        let progress = getProgress();

        const project = this.dataset.project;
        const currentCurationObj = progress.filter(
          (curation) => curation.cve == getCurrentCuration()
        );

        if (currentCurationObj.length) {
          currentCurationObj[0].project = project;
        }

        localStorage.setItem("VHPWizardStatus", JSON.stringify(progress));
        let questions = loadQuestions(project);
      },
      false
    );
  }

  document.getElementById("resetButton").addEventListener(
    "click",
    function () {
      resetProgress();
    },
    false
  );

  $("#open-menu").on("click", function () {
    setTimeout(function () {
      $("#YAMLOutput").trigger("focus");
    }, 1000);
  });

  $("#CopyYAML").on("click", function () {
    const yamlField = document.getElementById("YAMLOutput");
    const yamlOutput = yamlField.value;
    // Select the text field
    yamlField.select();
    yamlField.setSelectionRange(0, yamlOutput.length); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(yamlOutput);

    doToast("YAML copied to clipboard");
  });

  function downloadYml() {
    const yamlField = document.getElementById("YAMLOutput");
    const yamlOutput = yamlField.value;
    let fileName = "curation.yml";

    //Check if question is answered. if so, set it as the filename
    let cve = checkForSavedAnswer("CVE");
    if (cve) {
      fileName = `${cve}.yml`;
    }
    // It works on all HTML5 Ready browsers as it uses the download attribute of the <a> element:
    const element = document.createElement("a");

    //A blob is a data type that can store binary data
    // “type” is a MIME type
    // It can have a different value, based on a file you want to save
    const blob = new Blob([yamlOutput], { type: "plain/text" });
    //createObjectURL() static method creates a DOMString containing a URL representing the object given in the parameter.
    const fileUrl = URL.createObjectURL(blob);

    //setAttribute() Sets the value of an attribute on the specified element.
    element.setAttribute("href", fileUrl); //file location
    element.setAttribute("download", fileName); // file name
    element.style.display = "none";

    //use appendChild() method to move an element from one element to another
    document.body.appendChild(element);
    element.click();

    //The removeChild() method of the Node interface removes a child node from the DOM and returns the removed node
    document.body.removeChild(element);
  }
  $("#download").on("click", function () {
    downloadYml();
  });
  $("#cve-lookup").select2({
    placeholder:
      "Search our database for the CVE you want to curate <i class='fa-solid fa-search'></i>",
    escapeMarkup: function (markup) {
      return markup;
    },
    width: "100%",
    allowClear: true,
  });
  const tooltippedElements = document.querySelectorAll("span[data-tooltip]");
  tooltippedElements.forEach((element) => {
    var elem = new Foundation.Tooltip($(element));
  });
}
