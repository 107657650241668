import $ from 'jquery'
import _ from 'lodash'
import 'datatables.net'
import 'datatables.net-responsive-dt'
import 'datatables.net-zf'
import {vulnProject, ellipsizedFilepath} from '../global/dataToPrettyStrings'

export default function OffenderTable(offenders){

    this.offenders = offenders;
    this.project_id = 0; //project_id 0 is "all" projects

    this.init = function() {
        this.rebuild(this.offenders);
    }

    $.urlParam = function(name) {
        var results = new RegExp('[\?&]' + name + '=([^$#]*)').exec(window.location.href);
        if (results==null) {
            return '';
        } else {
            return results[1];
        }
    }

    this.languageInfo = function() {
        const searchSuggestions = [
            'clipboard',
            'gpu',
            'cpp',
            'auth',
            'xml',
            'util',
            'url',
            'ssl',
            'Makefile'
        ]
        return {
            search: "", // nothing in the placeholder;
            searchPlaceholder: `Try "${_.sample(searchSuggestions)}"`,
            lengthMenu: " Show _MENU_",
            info: "Showing _START_ to _END_ of _TOTAL_ offenders",
            infoEmpty: "Showing 0 to 0 of 0 entries",
            infoFiltered: "(filtered from _MAX_ total offenders)",
        };
    }

    this.renderAsLink = function(data, row) {
        return `<a class="dt_row" href="/filepaths/${row.slug}">${data}</a>`;
    }

    this.renderFilepathCell = function(row){
        const prefixLen = 20
        const maxLen = 75
        const fp = ellipsizedFilepath(row.filepath, prefixLen, maxLen)
        const filepathStr = `
            <a href="/filepaths/${row.slug}" class="filepath">
                <i class="vhp-icon-file"></i> <span data-tooltip tabindex="0" title="${fp[0]}">${fp[1]}</span>
            </a>
        `

        const reducer = function(str, vals, cve){
            let shade = ''
            if(vals.upvotes == 0)      { shade = 'none' }
            else if(vals.upvotes < 10) { shade = 'low'  }
            else if(vals.upvotes < 20) { shade = 'medium'  }
            else                       { shade = 'high'  }
            const newItemStr = `
            <li><a class="upvotes-${shade}"
                   href="/${cve}"
                   title="${vals.upvotes} upvotes">
                ${vals.nickname} ${cve}
                </a>
            </li>`
            return str + newItemStr
        }
        const itemStr = _.reduce(row.cves, reducer, "")
        const cveListStr = `<ul class="cve-listing">${itemStr}</ul>`
        return filepathStr + cveListStr
    }
    // console.log($.urlParam('search'))
    this.rebuild = function(jsonData){
        $('#offenderTable').DataTable({
            "search": {"search": $.urlParam('search')},
            responsive: true,
            // DOM option here is SUPER confusing.
            // See https://datatables.net/reference/option/dom
            // and https://github.com/DataTables/Dist-DataTables-Foundation/blob/master/js/dataTables.foundation.js#L56-L60
            dom: "<'row grid-x'<'small-2 columns cell'l><'small-3 small-offset-7 columns cell'f>r>"+
                 "t"+
                 "<'row grid-x'<'small-3 columns cell'i><'small-9 columns cell'p>>",
            destroy: true,
            autoWidth: false,
            lengthMenu: [
                [10, 25, 50, 100],
                ["10", "25", "50", "100"]
            ],
            data: jsonData,
            order: [[2, "desc"]], /* Default sort */
            columns:  [
                {
                    title: 'Project',
                    data: null,
                    defaultContent: '',
                    className: 'project',
                    responsivePriority: 2,
                    width: "8em",
                    render: (data, type, row) => this.renderAsLink(vulnProject(data) + data.project_name, row)
                },
                {
                    title: 'File & Vulnerabilities',
                    data: null,
                    defaultContent: '',
                    className: 'filepath',
                    responsivePriority: 1,
                    render: (data, _type, _row) => this.renderFilepathCell(data)
                },
                {
                    title: 'Fixes',
                    data: 'num_fixes',
                    defaultContent: '',
                    responsivePriority: 3,
                    width: '5em',
                    render: (data, type, row) => this.renderAsLink(data, row)
                },
                {
                    title: 'CVEs',
                    data: 'num_cves',
                    defaultContent: '',
                    responsivePriority: 4,
                    width: '5em',
                    render: (data, type, row) => this.renderAsLink(data, row)
                },
            ],
            language: this.languageInfo(),
            initComplete: function () {
                $('#loading').remove();
            }
        });
    }
}
