import $ from 'jquery'

const flags = {
  'hello-world' : {
    title: 'Hello, World!',
    desc: 'Place the text "Hello, World!" (without the quotes) in the field.',
    answer: 'Hello, World!',
  }
}

export default function onTour() {

  $('.ctf-flag').append(function(i, elem){
    const flag = flags[this.id];
    return `
    <h3>${flag.title}</h3>
    ${flag.desc}
    <input class="ctf-input" id="input-${this.id}"></input>
    <span class="result" id="result-${this.id}"></span>
    `
  })

  $('.ctf-input').on("change", function(){

  })


}
