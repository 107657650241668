import $ from 'jquery'
import { buildDevPie as buildDevPie } from './dev-pie'
import { keyCommitsTable as keyCommitsTable } from './keyCommitsTable'
import { vulnTable as vulnTable } from '../vulnerabilities/vulnTable'

function contributors_succeded(jsonData) {
  $('#dev_pie #loading').hide()
  buildDevPie(jsonData);
}

function contributors_failed(jsonData) {
  $('#dev_pie #loading').hide();
  $('#dev_pie #loading-failed').show();
}

function commits_succeded(jsonData) {
  $('#commits #loading').hide();
  keyCommitsTable(jsonData, '#edit_table');
}

function commits_failed(jsonData) {
  $('#commits #loading').hide();
  $('#commits #loading-failed').show();
}

function vulnerabilities_succeded(vulns_response, tags_response) {
  $('#vulnerabilities #loading').hide();
  vulnTable(vulns_response[0], tags_response[0], '#vulndatatable');
}

function vulnerabilities_failed(vulns_response, tags_response) {
  $('#vulnerabilities #loading').hide();
  $('#vulnerabilities #loading-failed').show();
}

export default function onFilepathsShow() {
  $.ajax({
    url: `/api/filepaths/${filepath_slug}/contributors`,
    dataType: 'json'
  }).then(contributors_succeded, contributors_failed);

  $.when(
    $.ajax({
      url: `/api/filepaths/${filepath_slug}/vulnerabilities`,
      data: { short_descriptions: true },
      dataType: 'json'
    }),
    $.ajax({
      url: `/api/tags?map=true`,
      dataType: "json",
    })
  ).then(vulnerabilities_succeded, vulnerabilities_failed);

  $.ajax({
      url: `/api/filepaths/${filepath_slug}/commits`,
      dataType: 'json'
  }).then(commits_succeded, commits_failed);
}
