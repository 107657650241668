import $ from 'jquery'
import 'datatables.net'
import 'datatables.net-responsive-dt'
import 'datatables.net-zf'

function buildTopNav() {
    $('#datatable_wrapper').prepend('<div id="table-nav-top"></div>');
    const nav = $('#table-nav-top');
    nav.addClass('table-nav grid-x');

    const nav_length = $('#datatable_length');
    nav_length.addClass('table-nav-show-entries cell small-9')
    nav.append(nav_length);

    const nav_search = $('#datatable_filter label');
    nav_search.addClass('table-nav-search cell small-3')
    nav.append(nav_search);
}

function languageInfo() {
    return {
        search: "", // nothing in the placeholder;
        searchPlaceholder: 'Search',
        lengthMenu: "Show _MENU_",
        info: "Showing _START_ to _END_ of _TOTAL_ developers",
        infoEmpty: "Showing 0 to 0 of 0 entries",
        infoFiltered: "(filtered from _MAX_ total developers)",
    };
}

function load_succeded(jsonData) {
    $('#datatable').dataTable({
        destroy: true,
        data: jsonData,
        rowCallback: function(row, data, index){
          $(row).on('click', function(){
            var url = window.location.href;
            if (url.slice(-1) == "/"){
              url += data.id;
            } else {
              url += '/' + data.id;
            }
            window.location.assign(url);
          })
        },
        columnDefs: [
          { "width": "10%", "targets": 0 }
        ],
        columns: [
            { title: 'Nickname', data: 'nickname', defaultContent: ''},
        ],
        language: languageInfo(),
        initComplete: function () {
            $('#loading').remove();
            buildTopNav();
          }
    });
}

function load_failed(jsonData) {
    $('#loading').hide();
    $('#loading-failed').show();
}

export default function onDevelopersIndex() {
    $.ajax({
        url: "/api/developers",
        dataType: 'json'}).then(load_succeded, load_failed);

    $('#datatable').on('mouseover', 'tbody tr', function() {
        $(this).css({
           'cursor': 'pointer',
            'color': 'blue'
        });
    })

    $('#datatable').on('mouseout', 'tbody tr', function() {
        $(this).css({
            'cursor': 'default',
            'color': 'inherit'
        });
    })
}
