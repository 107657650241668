import $ from "jquery";
import _ from "lodash";
import "datatables.net";
import "datatables.net-responsive-dt";
import "datatables.net-zf";
import {
  vulnLink,
  vulnProject,
  vulnName,
  friendlyCWE,
} from "../global/dataToPrettyStrings";
import SimpleDate from "../global/simpleDate";
/*
 Main listing of all vulnerabilities
*/
export function vulnTable(jsonData, tagMap, tableName = "#datatable") {
  return $(tableName).dataTable({
    responsive: true,
    deferRender: true,
    destroy: false,
    data: jsonData,
    order: [[2, "desc"]] /* Default sort */,
    columns: columnsInfo(tagMap),
    language: languageInfo(),
    initComplete: function () {
      buildTopNav(tagMap, tableName);
      const filterIDs = [ "#cwe_filters", "#lesson_filters", "#life_filters", "#tag_filters"]
      this.api()
        .columns(3)
        .every(function () {
          var column = this;
          filterIDs.forEach((filterID) => {
            $(filterID).on("change", function () {
              // Filter by ALL dropdowns, so draw based on ALL vals
              let searchBy = filterIDs.map((f) => $(f).val()).join(" ")
              column.search(searchBy).draw();
            });
          })
        });
    },
  });
}

export function updateVulnTable(tbl, jsonData) {
  tbl.api().rows.add(jsonData);
  $("#downloading-more").remove();
  tbl.api().draw();
}

function columnsInfo(tagMap) {
  return [
    {
      title: '<i class="vhp-icon-vulnerability"></i> Vulnerability',
      data: null,
      defaultContent: "",
      class: "text-left",
      responsivePriority: 1,
      orderable: false,
      render: (data) => renderVuln(data),
    },
    {
      title: '<i class="vhp-icon-announce"></i> Date',
      data: null,
      defaultContent: "",
      class: "text-center",
      responsivePriority: 4,
      width: "9em",
      orderable: true,
      render: (data) =>
        vulnLink(data, new SimpleDate(data.announced).shortFormat()),
    },
    {
      title: '<i class="vhp-icon-upvote"></i>',
      data: "upvotes",
      defaultContent: "0",
      class: "text-center",
      orderable: true,
      responsivePriority: 2,
    },
    {
      title: '<i class="vhp-icon-tags"></i> Tags',
      data: null,
      defaultContent: "",
      width: "50%",
      class: "text-left",
      responsivePriority: 3,
      orderable: false,
      render: (data) => renderTags(data, tagMap),
    },
  ];
}

function renderVuln(data) {
  return vulnLink(
    data,
    `
    <div class="vuln-table-title">${
      (data, vulnProject(data) + vulnName(data))
    }</div>

  `
  ) + `<div>${data.short_desc}</div>`;
}

function languageInfo() {
  const searchSuggestions = [
    "32",
    "64",
    "bounty",
    "char",
    "clipboard",
    "cpu",
    "dependency",
    "discovered",
    "error",
    "fix",
    "forgot",
    "gpu",
    "heap",
    "i18n",
    "i18n",
    "injection",
    "lesson",
    "lifetime",
    "origin",
    "overflow",
    "project",
    "sandbox",
    "severity",
    "spoof",
    "ssl",
    "stack",
    "subsystem",
    "thread",
    "ui",
    "util",
  ];
  return {
    search: "",
    searchPlaceholder: `Try "${_.sample(searchSuggestions)}"`,
    lengthMenu: "Show _MENU_",
    info: "Showing _START_ to _END_ of _TOTAL_ vulnerabilities",
    infoEmpty: "",
    infoFiltered: "(filtered from _MAX_ total vulnerabilities)",
  };
}

function buildFilterOpts(dropdownID, sortedMap, filter) {
  _.each(sortedMap, function(tag, _id) {
    if(filter(tag.name)){
      $(dropdownID).append(
        `<option value="${tag.name}" data-tag-id="${tag.shortname}">
          ${tag.name}
        </option>`
      )
    }
  })
}

function buildTopNav(tagMap, tableName) {
  $(`${tableName}_wrapper`).prepend('<div id="table-nav-top"></div>');
  var nav = $("#table-nav-top");

  nav.addClass("table-nav grid-x");

  var nav_length = $(`${tableName}_length`);
  nav_length.addClass("table-nav-show-entries cell small-1");
  nav.append(nav_length);

  let sortedMap = Object.values(tagMap).sort((a,b) =>
                    a.name.localeCompare(b.name))

  nav.append(`
    <div class="table-nav-filter hidden">
      <select id="cwe_filters" name="tag_filters" class="tag-filter">
        <option selected value> -- Filter by Type -- </option>
      </select>
    </div>`);
  buildFilterOpts("#cwe_filters", sortedMap, (s) => s.startsWith("CWE"))

  nav.append(`
    <div class="table-nav-filter hidden">
      <select id="lesson_filters" name="tag_filters" class="tag-filter">
        <option selected value> -- Filter by Lesson -- </option>
      </select>
    </div>`);
  buildFilterOpts("#lesson_filters", sortedMap, (s) => s.startsWith("Lesson"))

  nav.append(`
  <div class="table-nav-filter hidden">
    <select id="life_filters" name="tag_filters" class="tag-filter">
      <option selected value> -- Filter by Lifetime -- </option>
    </select>
  </div>`);
  buildFilterOpts("#life_filters", sortedMap, (s) => s.startsWith("Lifetime"))

  nav.append(`
  <div class="table-nav-filter hidden">
    <select id="tag_filters" name="tag_filters" class="tag-filter">
      <option selected value> -- Filter by Other Tags -- </option>
    </select>
  </div>`);
  buildFilterOpts("#tag_filters", sortedMap, (s) => !s.startsWith("CWE") &&
    !s.startsWith("Lesson") &&
    !s.startsWith("Lifetime") &&
    s.indexOf("subsystem") == -1
  )

  nav.append(`
    <div class="table-nav-drawer-toggle cell small-1 small-offset-8">
      <input id="drawer-toggle" type="checkbox" checked />
      <label for="drawer-toggle" title="Show/Hide filters"/>
    </div>
  `)

  var nav_search = $(`${tableName}_filter label`);
  nav_search.addClass("table-nav-search cell small-2");
  $("#drawer-toggle").on("click", (e) => onFilterDrawerOpen(e))
  nav.append(nav_search);

  $("#datatable_info").append(`
    <span id='downloading-more'>. Downloading more...</span>
  `);
}

function renderTags(vuln, tagMap) {
  let str = "<span class='tag-list'>";
  for (let t of vuln.tag_json) {
    const tag = tagMap[t.id];
    str += `
      <a href="/tags/${tag.shortname}" class="tag-list-item">
        <i class="fas vhp-icon-${tag.icon}" style="color: ${tag.color}"></i>
        <span>${friendlyCWE(tag.name)}</span>
      </a>
    `;
  }

  str += "</span>";
  return str;
}

function onFilterDrawerOpen(event) {
  $('.table-nav-filter').toggleClass(["hidden", "cell", "small-2"])
  $('.table-nav-drawer-toggle').toggleClass("small-offset-8")
}