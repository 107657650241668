import {vhpMarkdown} from './vhpMarkdown'

export default function articleListing(jsonData, container = '#article-listing') {
  const blurbCounts = {
    '#blurb-1big-template' : 1,
    '#blurb-4even-template' : 4,
    '#blurb-1wide-template' : 1,
    '#blurb-1big1small-template' : 2,
  }
  const numTemplates = _.size(blurbCounts);
  const bMax = _.reduce(blurbCounts, (max, v, _k) => max = v > max ? v : max, 0)
  let articlesLeft = jsonData.length;
  let i = 0;
  while(articlesLeft > bMax){
    const templateID = Object.keys(blurbCounts)[i];
    addBlurbRow(container, templateID)
    i = (i + 1) % numTemplates;
    articlesLeft -= blurbCounts[templateID];
  }
  //for the last few we add a single wide row.
  _.times(articlesLeft, () => addBlurbRow(container, '#blurb-1wide-template'))

  $('.hidden').remove() //Remove leftover blurb templates

  let blurbs = $('.blurb-text')
  let blurbArts = $('.blurb-art')
  for (let i = 0; i < jsonData.length; i++) {
    let article = jsonData[i]
    $(blurbArts[i]).html(`<div class="vhp-art-${article.art}"/>`)
    blurbs[i].innerHTML = vhpMarkdown(`
### ${article.title} ###
${article.blurb}
`) + `<a href="/articles/${article.slug}" class="button read-more">Read more</a>`
  }
}

export function articleListingOneSize(jsonData, container = '#article-listing-same') {
  _.times(jsonData.length,() => addBlurbRow(container, '#blurb-1wide-template'))
  let blurbs = $(container + ' .blurb-text');
  let blurbArts = $(container + ' .blurb-art');
  for (let i = 0; i < jsonData.length; i++) {
    let article = jsonData[i];
    $(blurbArts[i]).html(`<div class="vhp-art-${article.art}"/>`);
    blurbs[i].innerHTML = vhpMarkdown(`
### ${article.title} ###
${article.blurb}
[Full Article](/articles/${article.slug})
    `);
  }
}

function addBlurbRow(container, templateID) {
  const blurbRow = $(templateID).clone();
  blurbRow.removeAttr('id').removeClass('hidden');
  $(container).append(blurbRow).append(`<hr/>`);
}
