import $ from 'jquery'
import 'datatables.net'
import 'datatables.net-responsive-dt'
import 'datatables.net-zf'
import { hideSinglePagePagination as hideSinglePagePagination } from '../global/datatablesTweaks'
import SimpleDate from '../global/simpleDate'
import punchcard from './punchcard'

function buildTopNav() {
    $('#datatable_wrapper').prepend('<div id="table-nav-top"></div>');
    const nav = $('#table-nav-top');
    nav.addClass('table-nav grid-x');

    const nav_length = $('#datatable_length');
    nav_length.addClass('table-nav-show-entries cell small-9')
    nav.append(nav_length);

    const nav_search = $('#datatable_filter label');
    nav_search.addClass('table-nav-search cell small-3')
    nav.append(nav_search);

    $('#datatable_wrapper input').on("input", function () {
        let value = $(this).val();
        // If a range of dates are entered enclosed in parentheses, switch to
        // regex and non-smart mode. This occurs when the user clicks on a chiclet.
        if (/^\(.*\)$/.test(value)) {
            $('#datatable').dataTable()
                .api()
                .search(value, true, false)
                .draw();
        // Otherwise, regex mode is turned off and smart filtering is enabled.
        } else {
            $('#datatable').dataTable()
                .api()
                .search(value, false, true)
                .draw();
        }
    });
}

function languageInfo() {
    return {
        search: "", // nothing in the placeholder
        searchPlaceholder: 'Search',
        lengthMenu: "Show _MENU_",
        info: "Showing _START_ to _END_ of _TOTAL_ commits",
        infoEmpty: "Showing 0 to 0 of 0 entries",
        infoFiltered: "(filtered from _MAX_ total commits)",
    };
}

// Coalesce commit dates into an array of date strings
function extractDates(jsonData) {
    var commit_dates = [];
    for (let i in jsonData) {
        commit_dates.push(new SimpleDate(jsonData[i].date_created).shortFormat());
    }
    return commit_dates;
}

function createFrequencyObject(dates, minDate, maxDate) {
  var commit_frequencies = {};
  let pointerDate = new SimpleDate({year: minDate.getYear(), month: minDate.getMonth(), day: minDate.getDayOfMonth()});
  while(maxDate.isDateSameOrAfter(pointerDate)) {
    let weekStart = pointerDate.createWeekDay(0); // Sunday
    let weekEnd = pointerDate.createWeekDay(6); // Saturday

    // Ensure that date ranges don't go beyond the year of the date d.
    // Each year is displayed on its own row in the punchcard, so this is necessary.
    if (weekStart.getYear() != weekEnd.getYear()) {
      if (weekStart.getYear() == pointerDate.getYear()) {
        weekEnd = new SimpleDate({year: weekStart.getYear(), month: weekStart.getMonth(), day: weekStart.getLastDayOfMonth()});
      } else {
        weekStart = new SimpleDate({year: weekEnd.getYear(), month: weekEnd.getMonth(), day: 1});
      }
    }

    let key = weekStart.shortFormat() + " to " + weekEnd.shortFormat();

    let dFormat = pointerDate.shortFormat();
    if (dates.includes(dFormat)) {
      // Add the number of occurrences of d in dates
      commit_frequencies[key] += dates.filter(pointerDate => pointerDate === dFormat).length;
    } else if (commit_frequencies[key] === undefined) {
      commit_frequencies[key] = 0;
    }

    pointerDate = pointerDate.createNextDay();
  }
  return commit_frequencies;
}

function setPunchcardUp(jsonData) {
  var dates = extractDates(jsonData);

  var min = new SimpleDate(8640000000000000); // Max date
  var max = new SimpleDate(-8640000000000000); // Min da

  // Calculate min and max dates
  for (var i = 0; i < dates.length; i++) {
    min = min.getSmallerDate(new SimpleDate(dates[i]));
    max = max.getLargerDate(new SimpleDate(dates[i]));
  }

  // Begining of First Year - End of Last Year
  min = new SimpleDate({year: min.getYear(), month: 0, day: 1});
  max = new SimpleDate({year: max.getYear(), month: 11, day: 31});
  min.shortFormat();
  max.shortFormat();

  const settings = {
    chicHeight: 14,
    chicWidth: 14,
    minDate: min.createJSDate(),
    maxDate: max.createJSDate(),
  }

  var punch = new punchcard(settings);
  punch.init();

  var frequencies = createFrequencyObject(dates, min, max);
  punch.plotActivities(frequencies);
}

function commitLink(data, str) {
  return `<a class="dt_row" href="/commits/${data.commit_hash}">${str}</a>`
}

function load_succeded(jsonData) {
  $('#hpunchcard-loading').remove();
  setPunchcardUp(jsonData);

  $(window).resize(function() {
      setPunchcardUp(jsonData);
  });

  var table = $('#datatable').DataTable({
    responsive: true,
    destroy: true,
    data: jsonData,
    order: [[1, "asc"]],
    language: languageInfo(),
    initComplete: function () {
      $('#loading').remove();
      buildTopNav();
    },
    preDrawCallback: (settings) => hideSinglePagePagination(settings),
    columns: [
      { title: 'Commit Hash', data: null, defaultContent: '',
        render: (data) => commitLink(data, data.commit_hash.substring(0, 10))
      },
      { title: 'Date Created', data: null, defaultContent: '',
        render: (data) => commitLink(data, new SimpleDate(data.date_created).shortFormat())
      },
      { title: 'Lines Changed', data: null, defaultContent: '',
        render: (data) => commitLink(data, data.churn == null ? '' : data.churn)
      },
      { title: 'Abbreviated Commit Message', data: null, defaultContent: '',
        render: (data) => commitLink(data, data.message.split('\n')[0])
      }
    ]
  });
}

function load_failed(jsonData) {
    $('#loading').hide();
    $('#loading-failed').show();
}

export default function onDevelopersShow(){
    $.ajax({
        url: "/api/developers/" + $('#developer_id').text() + "/commits",
        dataType: 'json'}).then(load_succeded, load_failed)

    $('#datatable').on('mouseover', 'tbody tr', function() {
        $(this).css({
           'cursor': 'pointer',
            'color': 'blue'
        });
    });

    $('#datatable').on('mouseout', 'tbody tr', function() {
        $(this).css({
            'cursor': 'default',
            'color': 'inherit'
        });
    });

    // If mouse movement is detected, then the user is hopefully not a bot
    var input_detected = false;
    $(document).mousemove(function(event) {
        input_detected = true;
        $(document).off("mousemove");
    });

    // If keyboard input is detected, then the user is hopefully not a bot
    $(document).keypress(function(event) {
        input_detected = true;
        $(document).off("keypress");
    });

    $('#email_fetch').on('click', function() {
        if (!input_detected) {
          alert("We haven't detected any keyboard or mouse input from you. "
                + "To display the email address, please first show us you're not a bot.");
          return;
        }

        $.ajax({
            url: "/api/developers/" + $('#developer_id').text(),
            dataType: 'json',
            success: function(jsonData) {
              $('#email_fetch').hide();
              $('#developer_email')
                .html(jsonData.email)
                .show();
              $('#github_search')
                .prop('href', "https://github.com/search?q=" + jsonData.email + "&type=Users")
                .html("<i class=\"vhp-icon-github\"></i> Search for " + jsonData.email + " on GitHub")
                .show();
            },
            error: function() {
              if ($('#email_fetch').html() == "Failed. Retry?") {
                $('#email_fetch').html("Retry again?");
              } else {
                $('#email_fetch').html("Failed. Retry?");
              }
            }
        });
    });
}
