import $ from 'jquery'
import 'datatables.net'
import 'datatables.net-responsive-dt'
import 'datatables.net-zf'
import SimpleDate from '../global/simpleDate'

function load_succeded(jsonData) {
  $('#loading').hide();
  $('#datatable').dataTable({
    destroy: true,
    data: jsonData,
    rowCallback: function(row, data, index){
      $(row).on('click', function(){
        var url = window.location.href;
        if (url.slice(-1) == "/"){
          url += data['commit_hash'];
        } else {
          url += '/' + data['commit_hash'];
        }
        window.location.assign(url);
      })
    },
    columnDefs: [
      { "width": "10%", "targets": 0 }
    ],
    columns: [
        { title: 'Commit Hash', data: 'commit_hash', defaultContent: ''},
        { title: 'Author', data: 'email', defaultContent: ''},
        { title: 'Date Created', data: 'date_created', defaultContent: '',
          render: function(data) {
            return new SimpleDate(data.date_created).timestampFormat();
          }
        }
    ]
  });
}

function load_failed(jsonData) {
  $('#loading').hide();
  $('#loading-failed').show();
}

export default function onCommitsIndex() {
    $.ajax({
        url: "/api/commits",
        dataType: 'json'}).then(load_succeded, load_failed)

    $('#datatable').on('mouseover', 'tbody tr', function() {
        $(this).css({
           'cursor': 'pointer',
            'color': 'blue'
        });
    })

    $('#datatable').on('mouseout', 'tbody tr', function() {
        $(this).css({
            'cursor': 'default',
            'color': 'inherit'
        });
    })
}
